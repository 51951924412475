/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const types = {
  LOG_EVENT: "APP|LOG_EVENT",
};

const initialState = {
  eventsRecord: {},
};

export const actions = {
  logEvent: (params) => {
    return async (dispatch, getState) => {
      await dispatch({
        type: types.LOG_EVENT,
        payload: {
          ...getState().events.eventsRecord,
          ...(getState().entities.wallet.address && {
            [getState().entities.wallet.address?.toLowerCase()]: [
              { ...params, createdAt: new Date().toLocaleString() },
              ...(getState().events.eventsRecord[
                getState().entities.wallet.address?.toLowerCase()
              ] ?? []),
            ],
          }),
        },
      });
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_EVENT:
      return { ...state, eventsRecord: action.payload };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getEventList = (state) => {
  if (state.events.eventList) {
    state.events = { eventsRecord: {} };
  }
  return state.events.eventsRecord[
    state.entities.wallet.address?.toLowerCase()
  ];
};
