/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import {actionTypesConstructor} from "../utils";
import {combineReducers} from "redux";

export const types = {
    CATEGORY_FETCHER: actionTypesConstructor("APP|CATEGORY_FETCHER_REQUEST", "APP|CATEGORY_FETCHER_SUCCESS", "APP|CATEGORY_FETCHER_FAILURE"),
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
    loading: false
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const actions = {
    fetchCategories: (type, data=null) => {
        switch(type){
            case types.CATEGORY_FETCHER.failure():
                return {
                    type: types.CATEGORY_FETCHER.failure(),
                    payload: data
                }
            case types.CATEGORY_FETCHER.success():
                return {
                type: types.CATEGORY_FETCHER.success(),
                payload: data
            }
            default:
                return {
                    type: types.CATEGORY_FETCHER.request(),
                    payload: data
                }
        }
    },
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const data = (state = initialState, action) => {
    switch (action.type) {
        case types.CATEGORY_FETCHER.request():
            return {
                ...state,
                loading: true
            }
            case types.CATEGORY_FETCHER.success():
                return {
                    ...state,
                    loading: false,
                }
                case types.CATEGORY_FETCHER.failure():
                    return {
                        ...state,
                        loading: false,
                    }
        default:
            return state;
    }
}

const reducer = combineReducers({data})
export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
