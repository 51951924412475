import { combineReducers } from "redux";
import { Contract, ethers } from "ethers";
import { getElsTokenAddress, getPostingAddress } from "../../utils/contract";
import { chainMap } from "../../config";

const elsTokenAbi = require("../../abi/elsTokenAbi.json");
const elsTokenAddress = getElsTokenAddress();
const postAddress = getPostingAddress();

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
  CONFIGURE_WALLET: "APP|CONFIGURE|WALLET_CONNECT",
  SWITCH_CHAIN: "APP|CONFIGURE|SWITCH_CHAIN",
  SWITCH_ADDRESS: "APP|CONFIGURE|SWITCH_ADDRESS",
  CONFIGURE_WALLET_FAILURE: "APP|CONFIGURE|WALLET_CONNECT/FAILURE",
  CHECK_ALLOWANCE: "APP|CHECK|ALLOWANCE",
  WALLET_CONNECT: "APP|SET|WALLET_CONNECT",
  MAKE_APPROVE: "APP|MAKE_APPROVE",
  WALLET_DISCONNECT: "APP|DISCONNECT|WALLET",
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
  isWalletInstalled: true,
  isWalletConnected: false,
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  walletConfigurator: () => {
    return async (dispatch, getState) => {
      if (typeof window.ethereum !== "undefined") {
        const address = await loadUserWeb3();
        const chain = null;
        return await dispatch({
          type: types.CONFIGURE_WALLET,
          payload: { address, chain },
        });
      } else {
        console.log("[MetaMask:Installation:CHECK]: false");
        return await dispatch({
          type: types.CONFIGURE_WALLET_FAILURE,
          message: "Please install MetaMask",
          error: "Digital Wallet Installation Error!",
        });
      }
    };
  },
  switchChain: (chainId) => {
    return async (dispatch, getState) => {
      if (typeof window.ethereum !== "undefined") {
        await switchChain(chainId);
        return await dispatch({
          type: types.SWITCH_CHAIN,
          payload: { chain: chainId },
        });
      }
    };
  },
  setWallet: (wallet) => {
    return async (dispatch, getState) => {
      await dispatch({
        type: types.WALLET_CONNECT,
        payload: wallet,
      });
    };
  },
  setChain: (chainId) => {
    return async (dispatch, getState) => {
      if (typeof window.ethereum !== "undefined") {
        return await dispatch({
          type: types.SWITCH_CHAIN,
          payload: { chain: chainId },
        });
      }
    };
  },
  setAddress: (address) => {
    return async (dispatch, getState) => {
      if (typeof window.ethereum !== "undefined") {
        return await dispatch({
          type: types.SWITCH_ADDRESS,
          payload: { address },
        });
      }
    };
  },
  checkAllowance: () => {
    return async (dispatch, getState) => {
      const { address } = getState().entities.wallet;
      if (address) {
        const globalWin = window;
        const provider = new ethers.providers.Web3Provider(globalWin.ethereum);
        const elsTokenContract = new Contract(
          elsTokenAddress,
          elsTokenAbi,
          provider
        );
        const allowance = await elsTokenContract.allowance(
          address,
          postAddress
        );
        await dispatch({
          type: types.CHECK_ALLOWANCE,
          payload: parseFloat(ethers.utils.formatEther(allowance)) > 0,
        });
      }
    };
  },
  makeApprove: () => {
    return async (dispatch, getState) => {
      const { address, allowance } = getState().entities.wallet;
      const globalWin = window;
      const provider = new ethers.providers.Web3Provider(globalWin.ethereum);
      const signer = provider.getSigner();
      const elsTokenContract = new Contract(
        elsTokenAddress,
        elsTokenAbi,
        signer
      );

      if (!allowance) {
        const approveTx = await elsTokenContract.approve(
          postAddress,
          ethers.constants.MaxUint256
        );
        try {
          await approveTx.wait();
          console.log(`Transaction mined succesfully: ${approveTx.hash}`);
          await dispatch({
            type: types.MAKE_APPROVE,
            payload: true,
          });
        } catch (error) {
          console.log(`Transaction failed with error: ${error}`);
        }
      }
    };
  },
  disconnectWallet: () => {
    return async (dispatch, getState) => {
      await dispatch({
        type: types.WALLET_DISCONNECT,
      });
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const data = (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIGURE_WALLET_FAILURE:
      return { ...state, isWalletInstalled: false };
    case types.CONFIGURE_WALLET:
      return action.payload.address
        ? { ...state, isWalletConnected: true }
        : { ...state };
    case types.WALLET_CONNECT:
      return { ...state, isWalletConnected: true };
    case types.WALLET_DISCONNECT:
      return initialState;
    default:
      return state;
  }
};

const reducer = combineReducers({ data });
export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getWalletStatus = (state) => {
  return state.wallet.data;
};

export const loadUserWeb3 = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_accounts",
  });

  return accounts.length === 0 ? null : accounts[0];
};

export const getChain = () => {
  return window.ethereum?.chainId;
};

export const switchChain = async (chainId) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  } catch (error) {
    if (error?.code === 4902) {
      if (chainId === "0x89") {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x89",
              chainName: "Polygon Mainnet",
              rpcUrls: [chainMap[chainId].jsonRpcProvider],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://polygonscan.com"],
            },
          ],
        });
      } else if (chainId === "0x13881") {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x13881",
              chainName: "Mumbai",
              rpcUrls: [chainMap[chainId].jsonRpcProvider],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://mumbai.polygonscan.com"],
            },
          ],
        });
      }
    } else {
      throw error;
    }
  }
};
