import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import useCategory from "@/hooks/aboutCategory/useCategory";
import { Grid, Link, Breadcrumbs, useMediaQuery } from "@mui/material";
import PostsList from "@/components/PostsList";
import Loading from "@/components/Loading";
import { TextField } from "@mui/material";
import useSearch from "@/hooks/aboutSearch/useSearch";
import emitter from "@/utils/eventEmitter";
import searchIcon from "@/assets/icon/search.svg";
import communityIcon from "@/assets/icon/community.svg";
import codeSnippetIcon from "@/assets/icon/codeSnippet.svg";
import projectAnnouncementsIcon from "@/assets/icon/projectAnnouncements.svg";
import forSaleIcon from "@/assets/icon/forSale.svg";
import servicesIcon from "@/assets/icon/services.svg";
import jobsIcon from "@/assets/icon/jobs.svg";
import arrowIcon from "@/assets/icon/arrow.svg";
import resumeIcon from "@/assets/icon/resume.svg";
import styles from "./index.module.css";
import FooterPreview from "@/components/FooterPreview";
import { useConnect } from "wagmi";
import { getChain } from "@/redux/modules/wallet";
import useWallet from "@/hooks/aboutWallet/useWallet";
import WalletConnect from "@/components/WalletConnect";
import { useQuery } from "urql";
import { searchPostsByTitleInParent, searchPostsByTitleOrDes } from "@/graphQL";
import KeepAlive, {
  withAliveScope,
  useAliveController,
} from "react-activation";
import message from "@/components/Message";
import { chainMap, defaultChainId } from "@/config";
import { ethers } from "ethers";

const GRID_LAYOUT = "GRID_LAYOUT";
const TABS_LAYOUT = "TABS_LAYOUT";

const CategoryCard = ({
  category,
  index,
  index2,
  setLayout,
  setCurrentCategory,
  setCurrentVarieties,
  sx,
}) => {
  const [total, setTotal] = useState(
    localStorage.getItem(`category:${category?.id}-total`) ?? 0
  );

  const nav = useNavigate();
  const matches = useMediaQuery("(max-width:600px)");

  const query = searchPostsByTitleInParent;
  const variables = { id: category?.id, title: "" };

  const res =
    query &&
    variables &&
    useQuery({
      query,
      variables,
    });

  const [resultPosts, reexecuteQuery] = res || [];
  const { data, fetching, error } = resultPosts || {};

  useEffect(() => {
    const length = data?.postingItems?.filter((each) => !each.removed)?.length;
    if (length >= 0) {
      setTotal(length);
      localStorage.setItem(`category:${category?.id}-total`, length);
    }
  }, [data?.postingItems]);

  return (
    <Grid
      item
      container
      className={styles.singleCard}
      sx={{
        ...sx,
        boxShadow: matches
          ? `3px 3px ${category?.themeColor}`
          : `6px 6px ${category?.themeColor}`,
        padding: category?.label === "Resumes" ? "20px" : "64px 24px 40px",
        height:
          category?.label === "Resumes" && matches ? "90px !important" : "auto",
      }}
    >
      <Box
        className={styles.iconBox}
        sx={{
          height: category?.label === "Resumes" && matches && "40px !important",
        }}
      >
        <Box
          component={"img"}
          src={category?.icon}
          className={styles.icon}
        ></Box>
      </Box>
      <Grid
        item
        style={{
          padding: matches
            ? category?.label === "Resumes"
              ? "8px 0"
              : "16px 0 30px"
            : category?.label === "Resumes"
            ? "8px 0"
            : "16px 0 40px",
        }}
        xs={12}
      >
        <Box
          className={styles.label}
          onClick={() => {
            nav(`/category/${category?.id}`, {
              state: {
                category,
              },
            });
            // setCurrentCategory(category);
            // setLayout({ type: TABS_LAYOUT, reset: false });
          }}
          sx={{
            whiteSpace:
              category?.label === "Project Announcements" &&
              matches &&
              "unset !important",
            transform:
              category?.label === "Project Announcements" &&
              matches &&
              "scale(0.35) !important",
          }}
        >
          {category?.label}
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={12}
        // style={{ overflow: "auto", height: "200px" }}
      >
        <Grid
          item
          xs={12}
          className={styles.subLableBox}
          sx={{
            height: category.height,
            display: category?.label === "Resumes" && matches && "none",
          }}
        >
          {category?.varieties // : // ? [...category.varieties, ...category.varieties] // index === 1 && index2 === 0
            .map((variety, index) => {
              return (
                <Box className={styles.subLable}>
                  <Box
                    onClick={() => {
                      nav(`/category/${category?.id}/variety/${variety?.id}`, {
                        state: {
                          category,
                          variety,
                        },
                      });
                      // setCurrentCategory(category);
                      // setCurrentVarieties(variety);
                      // setLayout({ type: TABS_LAYOUT, reset: false });
                    }}
                  >
                    {variety?.label}
                  </Box>
                  <Box
                    component={"img"}
                    src={arrowIcon}
                    sx={{
                      ml: matches ? 0.5 : 1.5,
                    }}
                  ></Box>
                </Box>
              );
            })}
        </Grid>
      </Grid>
      <Box
        className={styles.moreListings}
        sx={{ marginTop: category?.label === "Resumes" && "0 !important" }}
        onClick={() => {
          nav(`/category/${category?.id}`, {
            state: {
              category,
            },
          });
          // setCurrentCategory(category);
          // setLayout({ type: TABS_LAYOUT, reset: false });
        }}
      >
        View All {total} Listings
        <Box
          className={styles.decoration}
          sx={{ background: category?.underlineColor }}
        ></Box>
      </Box>
    </Grid>
  );
};

const GridLayout = ({ setLayout, setCurrentCategory, setCurrentVarieties }) => {
  let { categoriesGetter } = useCategory();
  const [categories, setCategories] = useState(categoriesGetter);

  const chainConfig = chainMap[getChain()];
  const currentChainId = chainConfig?.inuse ? getChain() : defaultChainId;
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    categoriesGetter = categoriesGetter.map((item) => {
      if (item.label === "Community") {
        return {
          ...item,
          icon: communityIcon,
          themeColor: "#B794F4",
          underlineColor: "#A693F3",
          height: matches ? "50px" : "auto",
        };
      }
      if (item.label === "Services") {
        return {
          ...item,
          icon: servicesIcon,
          themeColor: "#90CDF4",
          underlineColor: "#90CDF4",
          height: matches ? "100px" : "auto",
        };
      }
      if (
        item.label ===
        (currentChainId === defaultChainId
          ? "Project Announcements"
          : "Discussion forums")
      ) {
        return {
          ...item,
          icon: projectAnnouncementsIcon,
          themeColor: "#F6E05E",
          underlineColor: "#F6E05E",
          height: matches ? "150px" : "auto",
        };
      }
      if (
        item.label ===
        (currentChainId === defaultChainId ? "For Sale" : "For sale")
      ) {
        return {
          ...item,
          icon: forSaleIcon,
          themeColor: "#4299E1",
          underlineColor: "#4299E1",
          height: matches ? "100px" : "auto",
        };
      }
      if (item.label === "Jobs") {
        return {
          ...item,
          icon: jobsIcon,
          themeColor: "#68D391",
          underlineColor: "#68D391",
          height: matches ? "100px" : "auto",
        };
      }
      if (
        item.label ===
        (currentChainId === defaultChainId ? "Source Codes" : "NFT")
      ) {
        return {
          ...item,
          icon: codeSnippetIcon,
          themeColor: "#4FD1C5",
          underlineColor: "#4FD1C5",
          height: matches ? "50px" : "auto",
        };
      }
      if (item.label === "Resumes") {
        return {
          ...item,
          icon: resumeIcon,
          themeColor: "#76E4F7",
          underlineColor: "#9DECF9",
          height: matches ? "20px" : "auto",
        };
      }

      return item;
    });

    if (currentChainId === defaultChainId) {
      const categoriesGetterCopy = JSON.parse(JSON.stringify(categoriesGetter));
      if (categoriesGetterCopy.length > 0) {
        // const originCategoriesGetter = JSON.parse(
        //   JSON.stringify(categoriesGetterCopy)
        // );
        categoriesGetterCopy[1] = JSON.parse(
          JSON.stringify(categoriesGetter[5])
        );
        categoriesGetterCopy[2] = JSON.parse(
          JSON.stringify(categoriesGetter[6])
        );
        categoriesGetterCopy[3] = JSON.parse(
          JSON.stringify(categoriesGetter[1])
        );
        categoriesGetterCopy[4] = JSON.parse(
          JSON.stringify(categoriesGetter[3])
        );
        categoriesGetterCopy[5] = JSON.parse(
          JSON.stringify(categoriesGetter[2])
        );
        categoriesGetterCopy[6] = JSON.parse(
          JSON.stringify(categoriesGetter[4])
        );
        setCategories(categoriesGetterCopy);
      }
    } else {
      setCategories(categoriesGetter);
    }
  }, [categoriesGetter]);

  return (
    <Grid sx={{ height: "calc(100% - 96px)" }}>
      {categories.length === 0 ? (
        <Loading height={"24vw"} />
      ) : (
        <Grid container className={styles.cardGrid}>
          {Array.from({ length: categories.length / 2 }).map((item, index) => {
            return (
              <Grid className={styles.verticleGrid}>
                {Array.from({
                  length:
                    currentChainId === defaultChainId && index === 0 ? 3 : 2,
                }).map((item, index2) => {
                  return (
                    <>
                      <CategoryCard
                        category={
                          categories[
                            index *
                              (currentChainId === defaultChainId && index === 0
                                ? 3
                                : 2) +
                              index2 +
                              (currentChainId === defaultChainId && index > 0
                                ? 1
                                : 0)
                          ]
                        }
                        index={index}
                        index2={index2}
                        setLayout={setLayout}
                        setCurrentCategory={setCurrentCategory}
                        setCurrentVarieties={setCurrentVarieties}
                        sx={{
                          "@media (min-width: 600px)": {
                            margin: index === 1 && "20px !important",
                          },
                          "@media (max-width: 600px)": {
                            // marginBottom: "56px !important",
                          },
                        }}
                      ></CategoryCard>
                    </>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

const SearchBox = ({ setLayout, sx }) => {
  const { searchDispatcher, searchParamsGetter } = useSearch();
  const nav = useNavigate();

  const handleChange = async (e) => {
    const title = e.currentTarget.value;
    const description = e.currentTarget.value;
    searchDispatcher(title, description);
    // if ((title || description) === "") {
    setLayout({ type: TABS_LAYOUT, reset: true });
    // }
    nav("/search");
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        m: "30px 0",
        borderRadius: "4px",
        p: "10px 12px",
        height: "40px",
        ...sx,
      }}
      className={styles.search}
    >
      <Box
        component={"img"}
        src={searchIcon}
        sx={{
          mr: 1,
        }}
      ></Box>
      <TextField
        variant="standard"
        placeholder="Search for post, community and everything else"
        onChange={handleChange}
        InputProps={{ disableUnderline: true, fontSize: "10px" }}
        fullWidth
        size="small"
        autoFocus={!!searchParamsGetter.title}
        value={searchParamsGetter.title}
        sx={{
          "& .MuiInputBase-input": {
            color: "#4A5568",
            lineHeight: "100%",
            fontFamily: "Satoshi-Regular",
            fontSize: 14,
            lineHeight: "100%",
            p: 0,
          },
        }}
      ></TextField>
    </Grid>
  );
};

const PreviewDashboard = () => {
  const [layout, setLayout] = useState({ type: GRID_LAYOUT, reset: false });
  const [categoryTabs, setCategoryTabs] = useState([
    {
      index: 0,
      label: "",
      name: "",
      value: "",
      id: 0,
      varieties: [{ index: 0, id: 0, label: "", name: "", value: "" }],
    },
  ]);
  const [currentCategory, setCurrentCategory] = useState();
  const [currentVarieties, setCurrentVarieties] = useState();
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(localStorage.getItem("total") ?? 0);
  const [welcome, setWelcome] = useState();
  const [blockNumber, setBlockNumber] = useState(0);

  const {
    searchDispatcher,
    searchParamsGetter: { title, description },
  } = useSearch();

  const nav = useNavigate();

  const { connectAsync, connectors } = useConnect();

  const { drop, dropScope, clear, getCachingNodes } = useAliveController();

  const { walletGetter, walletSetter } = useWallet();

  const query = searchPostsByTitleOrDes;
  const variables = { title: "" };

  const res =
    query &&
    variables &&
    useQuery({
      query,
      variables,
    });

  const [resultPosts, reexecuteQuery] = res || [];

  const { data, fetching, error } = resultPosts || {};

  const createPost = async () => {
    if (!walletGetter.address) {
      if (typeof window.ethereum === "undefined") {
        message.warning({ content: "Please install MetaMask" });
        return;
      }
      const { account } = await connectAsync({
        connector: connectors[0],
      });
      const chain = getChain();
      console.log(`[WalletConnect]: account ${account}, chain: ${chain}`);
      walletSetter({
        address: account?.toLowerCase(),
        chain: getChain(),
      });
      localStorage.setItem("DisconnectWallet", "false");

      walletGetter.address = account?.toLowerCase();
    }
    await drop("post");
    nav(`/${walletGetter.address}/post/new`, {
      state: { new: true },
      // replace: true,
    });
  };

  useEffect(() => {
    const chainConfig = chainMap[getChain()];

    const jsonRpcProvider = chainConfig?.inuse
      ? chainConfig.jsonRpcProvider
      : chainMap[defaultChainId].jsonRpcProvider;

    const provider = new ethers.providers.JsonRpcProvider(jsonRpcProvider);

    provider.getBlockNumber().then((res) => {
      setBlockNumber(res);
    });
  }, [getChain()]);
  
  useEffect(() => {
    const length = data?.postingItems?.filter((each) => !each.removed)?.length;
    if (length >= 0) {
      setTotal(length);
      localStorage.setItem("total", length);
    }
  }, [data?.postingItems]);

  useEffect(() => {
    searchDispatcher("", "");
    window.scrollTo({ top: 0 });
    const hour = new Date().getHours();
    if (hour < 4) {
      setWelcome("GN");
    } else if (hour < 13) {
      setWelcome("GM");
    } else if (hour < 19) {
      setWelcome("GA");
    } else if (hour < 23) {
      setWelcome("GE");
    } else {
      setWelcome("GN");
    }
  }, []);

  return (
    <>
      {(title || description) === "" && layout.type === GRID_LAYOUT && (
        <Box className={styles.rightBright}>
          <Box className={styles.leftBright}>
            <Box className={styles.body}>
              <Box className={styles.title}>
                {welcome} there, welcome to ELS,{" "}
                <br className={styles.titleBR} />
                <Box className={styles.create} onClick={createPost}>
                  +Create your listing
                  <Box className={styles.decoration}></Box>
                </Box>{" "}
                today!
              </Box>
              <SearchBox setLayout={setLayout} />
              <Box className={styles.subtitle}>Explore by Categories</Box>
              <GridLayout
                setLayout={setLayout}
                currentCategory={currentCategory}
                currentVarieties={currentVarieties}
                categoryTabs={categoryTabs}
                setCategoryTabs={(categoryTabs) =>
                  setCategoryTabs(categoryTabs)
                }
                setCurrentCategory={(currentCategory) =>
                  setCurrentCategory(currentCategory)
                }
                setCurrentVarieties={(currentVarieties) =>
                  setCurrentVarieties(currentVarieties)
                }
              />
              <Box className={styles.footer}>
                <Box className={styles.postAList}>
                  {walletGetter.address ? (
                    <div className={styles.create} onClick={createPost}>
                      Create a post <Box className={styles.decoration}></Box>
                    </div>
                  ) : (
                    "Are you here to post a listing?"
                  )}
                </Box>

                {!walletGetter.address && (
                  <Box className={styles.walletConnectBox}>
                    <WalletConnect disabled></WalletConnect>
                  </Box>
                )}

                <Box className={styles.tip}>
                  Or,
                  <Link
                    className={styles.link}
                    href={process.env.REACT_APP_DOC}
                    target="_blank"
                  >
                    Read our tutorial <Box className={styles.decoration}></Box>
                  </Link>
                  and learn how our product works.
                </Box>
                <Box className={styles.blockHeight}>
                  Block Height: {blockNumber}
                </Box>
              </Box>
            </Box>
            <FooterPreview />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PreviewDashboard;
