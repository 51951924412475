import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import ethIcon from "@/assets/icon/eth.svg";
import arrowLeftIcon from "@/assets/icon/arrowLeft.svg";
import arrowRightIcon from "@/assets/icon/arrowRight.svg";
import fileIcon from "@/assets/icon/file.svg";
import arrowIcon from "@/assets/icon/arrow.svg";
import styles from "./index.module.css";
import "./index.css";
import Loading from "../Loading";
import { useMediaQuery } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function ImageBox({ item, index, showEthIcon, activeStep, autoPlay }) {
  const [isReplaced, setIsReplaced] = useState(false);
  const isImage = item.file?.type?.includes?.("image");
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Box
      key={index}
      className={styles.imageBox}
      sx={{
        width: !isImage && !autoPlay ? "100%" : "unset",
        margin: autoPlay && "0 12vw",
      }}
    >
      <Box
        position="relative"
        height={matches ? "346px" : "100%"}
        sx={{
          display: !isImage && "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: !isImage && !autoPlay ? "100%" : "unset",
          background: !isImage && !autoPlay && "#eff5f9",
        }}
      >
        <Box
          component="img"
          sx={{
            width: !isImage ? "50px" : "100%",
            height: !isImage ? "50px" : "100%",
            overflow: "hidden",
            objectFit: "scale-down",
          }}
          src={isImage ? item.upload : fileIcon}
          onError={(e) => {
            if (isReplaced) {
              return;
            }
            e.currentTarget.src = item.upload.replace(
              process.env.REACT_APP_PinataGateWay,
              process.env.REACT_APP_PinataGateWay2
            );
            setIsReplaced(true);
          }}
        />
        {/* {showEthIcon && (
            <Box component="img" className={styles.eth} src={ethIcon}></Box>
          )} */}
        {!isImage && autoPlay && (
          <Box
            className={styles.cid}
            sx={
              {
                // marginTop: autoPlay ? "10px" : 0,
                // height: autoPlay ? "auto" : 0,
              }
            }
          >
            {item.file.name && (
              <Box className={styles.fileName}>{item.file.name}</Box>
            )}
            ipfs://{item.uri}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function Swiper({ images, autoPlay = false, showEthIcon }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const maxSteps = images?.length;
  const isImage = images[activeStep].file?.type?.includes?.("image");

  const handleNext = (e) => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const onMouseEnterDownload = () => {
    if (isImage) {
      return;
    }
    setIsHover(true);
  };

  const onMouseLeaveDownload = () => {
    setIsHover(false);
  };

  const onClickDownload = async (e) => {
    if (isImage || downloadLoading) {
      return;
    }
    e.stopPropagation();
    if (images[activeStep].file?.type === "directory") {
      window.open(images[activeStep].upload);
      return;
    }
    setDownloadLoading(true);
    const res = await fetch(images[activeStep].upload);
    const data = await res.blob();
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => {
      const a = document.createElement("a");
      a.download = images[activeStep].file.name;
      a.href = reader.result;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadLoading(false);
    };
    reader.onerror = () => {
      setDownloadLoading(false);
    };
  };

  useEffect(() => {
    if (isImage) {
      setIsHover(false);
    }
  }, [activeStep]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      {/* {showEthIcon && (
        <Box component="img" className={styles.eth} src={ethIcon}></Box>
      )} */}
      {!isImage && (
        <Box
          className={styles.downloadBox}
          sx={{
            left: !isImage && (autoPlay ? "12vw" : "100px"),
            right: !isImage && (autoPlay ? "12vw" : "100px"),
          }}
        >
          <Box
            className={styles.download}
            sx={{
              opacity: !isHover && 0,
              cursor: !isImage && "pointer",
            }}
            onMouseEnter={onMouseEnterDownload}
            onMouseLeave={onMouseLeaveDownload}
            onClick={onClickDownload}
          >
            {downloadLoading ? (
              <Loading
                circularProgressSX={{
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              <Box component={"img"} src={arrowIcon}></Box>
            )}
          </Box>
          {!isImage && autoPlay && (
            <Box className={styles.cid}>
              {images[activeStep].file.name && (
                <Box className={styles.fileName}>
                  {images[activeStep].file.name}
                </Box>
              )}
              ipfs://{images[activeStep].uri}
            </Box>
          )}
        </Box>
      )}
      {autoPlay ? (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          className={styles.autoPlaySwipeableViews}
        >
          {images?.map((item, index) => (
            <ImageBox
              item={item}
              index={index}
              showEthIcon
              activeStep
              autoPlay={autoPlay}
            />
          ))}
        </AutoPlaySwipeableViews>
      ) : (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          className={styles.autoPlaySwipeableViews}
        >
          {images?.map((item, index) => (
            <ImageBox
              item={item}
              index={index}
              showEthIcon
              activeStep
              autoPlay={autoPlay}
            />
          ))}
        </SwipeableViews>
      )}

      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        className={styles.mobileStepper}
        sx={{
          ".MuiMobileStepper-dots": {
            display: maxSteps === 1 && "none !important",
          },
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <Box
              // component="img"
              className={styles.arrowRight}
              // src={rightArrowIcon}
            ></Box>
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <Box
              // component="img"
              className={styles.arrowLeft}
              // src={leftArrowIcon}
            ></Box>
          </Button>
        }
      />
    </Box>
  );
}

export default Swiper;
