import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { Box } from "@mui/material";
import styles from "./index.module.css";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const maxLength = 4;

export default function ChipsArray({ chipData, setChipData, onClickAdd }) {
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  return (
    <Paper
      sx={{
        position: "relative",
        display: "flex",
        // justifyContent: "center",
        // flexWrap: "wrap",
        listStyle: "none",
        padding: "0px 0.5px",
        m: 0,
        background: "white",
        border: "1px solid #666666",
        borderRadius: "2px",
        boxShadow: "unset",
        height: 40,
        boxSizing: "border-box",
        cursor: chipData.length < maxLength && "pointer",
      }}
      component="ul"
      onClick={() => {
        if (chipData.length === maxLength) {
          return;
        }
        onClickAdd();
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          // justifyContent: "center",
          // flexWrap: "wrap",
          listStyle: "none",
          m: 0,
          boxShadow: "unset",
          height: 38,
          boxSizing: "border-box",
          overflow: "auto",
          whiteSpace: "nowrap",
          marginRight: chipData.length < maxLength ? "25px" : "0",
        }}
        className="hideScrollbar"
      >
        {chipData.length > 0 ? (
          chipData.map((data) => {
            return (
              <ListItem key={data.key} onClick={(e) => e.stopPropagation()}>
                <Chip
                  //   icon={icon}
                  label={`${data.key}: ${data.label}`}
                  variant="outlined"
                  onDelete={handleDelete(data)}
                  sx={{
                    border: "1px solid rgb(189, 189, 189) !important",
                    borderRadius: "16px !important",
                    height: "100%",
                    cursor: "auto !important",
                  }}
                />
              </ListItem>
            );
          })
        ) : (
          <Box className={styles.tip}>Add a contact method</Box>
        )}
      </Box>
      {chipData.length < maxLength && (
        <Box className={styles.add} onClick={onClickAdd}>
          +
        </Box>
      )}
    </Paper>
  );
}
