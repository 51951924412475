import Box from "@mui/material/Box";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid, Breadcrumbs, useMediaQuery } from "@mui/material";
import PostsList from "@/components/PostsList";
import { TextField } from "@mui/material";
import useSearch from "@/hooks/aboutSearch/useSearch";
import emitter from "@/utils/eventEmitter";
import searchIcon from "@/assets/icon/search.svg";
import styles from "./index.module.css";
import Footer from "@/components/Footer";
import { useQuery } from "urql";
import { searchPostsByTitleOrDes } from "@/graphQL";
import useCategory from "@/hooks/aboutCategory/useCategory";
import CreatePostButton from "@/components/CreatePostButton";

const GRID_LAYOUT = "GRID_LAYOUT";
const TABS_LAYOUT = "TABS_LAYOUT";

function HomeTabs({ currentCategory, currentVarieties }) {
  const { searchDispatcher } = useSearch();
  const nav = useNavigate();

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          ".MuiBreadcrumbs-separator": {
            fontSize: "22px !important",
          },
          li: {
            fontFamily: "Satoshi-Regular",
            fontSize: "22px !important",
            color: "rgba(0, 0, 0, 0.45)",
            a: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          // m: "24px 24px 11.5px",
        }}
      >
        <Box
          underline="hover"
          onClick={() => {
            // setLayout({ type: GRID_LAYOUT, reset: false });
            // setCurrentCategory();
            // setCurrentVarieties();
            nav("/");
            // emitter.emit("resetDashboard");
          }}
          sx={{ cursor: "pointer" }}
          className={styles.firstLevel}
        >
          Home
        </Box>
        <Box
          className={styles.secondLevel}
          onClick={() => {
            nav(`/category/${currentCategory.id}`, {
              state: { category: currentCategory, clearSearch: true },
            });
            // setCurrentVarieties();
            searchDispatcher("", "");
          }}
        >
          {currentCategory.label}
        </Box>
        {currentVarieties?.label && (
          <Box className={styles.thirdLevel}>{currentVarieties.label}</Box>
        )}
      </Breadcrumbs>
      <Box
        sx={{
          // borderBottom: "1px solid #999",
          m: "11px 24px 0",
        }}
      ></Box>
    </Box>
  );
}

const SearchBox = ({ setLayout, sx }) => {
  const { searchDispatcher, searchParamsGetter } = useSearch();

  const handleChange = async (e) => {
    const title = e.currentTarget.value;
    const description = e.currentTarget.value;
    searchDispatcher(title, description);
    // if ((title || description) === "") {
    setLayout({ type: TABS_LAYOUT, reset: true });
    // }
    // navigate("/search");
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        m: "60px 0",
        borderRadius: "4px",
        p: "10px 12px",
        height: "40px",
        ...sx,
      }}
      className={styles.search}
    >
      <Box
        component={"img"}
        src={searchIcon}
        sx={{
          mr: 1,
        }}
      ></Box>
      <TextField
        variant="standard"
        placeholder="Search for post, community and everything else"
        onChange={handleChange}
        InputProps={{ disableUnderline: true, fontSize: "10px" }}
        fullWidth
        size="small"
        autoFocus={!!searchParamsGetter.title}
        value={searchParamsGetter.title}
        sx={{
          "& .MuiInputBase-input": {
            color: "#4A5568",
            lineHeight: "100%",
            fontFamily: "Satoshi-Regular",
            fontSize: 14,
            lineHeight: "100%",
            p: 0,
          },
        }}
      ></TextField>
    </Grid>
  );
};

const Category = () => {
  const [layout, setLayout] = useState({ type: TABS_LAYOUT, reset: false });
  const [categoryTabs, setCategoryTabs] = useState([
    {
      index: 0,
      label: "",
      name: "",
      value: "",
      id: 0,
      varieties: [{ index: 0, id: 0, label: "", name: "", value: "" }],
    },
  ]);
  const [currentCategory, setCurrentCategory] = useState();
  const [currentVarieties, setCurrentVarieties] = useState();
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(localStorage.getItem("total") ?? 0);

  const {
    searchDispatcher,
    searchParamsGetter: { title, description },
  } = useSearch();

  const params = useParams();
  const location = useLocation();
  const { categoriesGetter } = useCategory();
  const matches = useMediaQuery("(max-width:600px)");

  const query = searchPostsByTitleOrDes;
  const variables = { title: "" };

  const res =
    query &&
    variables &&
    useQuery({
      query,
      variables,
    });

  const [resultPosts, reexecuteQuery] = res || [];

  const { data } = resultPosts || {};

  const isGlobalSearch = !currentCategory && !currentVarieties;

  useEffect(() => {
    const length = data?.postingItems?.filter((each) => !each.removed)?.length;
    if (length >= 0) {
      setTotal(length);
      localStorage.setItem("total", length);
    }
  }, [data?.postingItems]);

  useEffect(() => {
    if (!location.state) {
      categoriesGetter.find((category) => {
        if (category.id === params.category) {
          params.category = {
            id: category.id,
            label: category.label,
          };
          if (params.variety) {
            return category.varieties.find((variety) => {
              //   console.log(variety.id, variety.name);
              if (variety.id === params.variety) {
                params.variety = {
                  id: variety.id,
                  label: variety.label,
                };
                return true;
              }
            });
          } else {
            return true;
          }
        }
      });
    }
    if (params?.variety) {
      setCurrentCategory(location.state?.category || params.category);
      setCurrentVarieties(location.state?.variety || params.variety);
      setLayout({ type: TABS_LAYOUT, reset: false });
    } else if (params?.category) {
      setCurrentCategory(location.state?.category || params.category);
      setCurrentVarieties();
      setLayout({ type: TABS_LAYOUT, reset: false });
    }
  }, [location]);

  // useEffect(() => {
  //   emitter.addListener("resetDashboard", () => {
  //     searchDispatcher("", "");
  //     setLayout({ type: GRID_LAYOUT, reset: false });
  //     setCurrentCategory();
  //     setCurrentVarieties();
  //   });
  //   return () => {
  //     emitter.removeAllListeners("resetDashboard");
  //   };
  // }, []);

  return (
    <>
      {layout.type === TABS_LAYOUT && (
        <Box
          className={styles.tabAndListwrapper}
          sx={{ top: !isGlobalSearch && "40px" }}
        >
          <Box className={styles.homeTabsWrapper}>
            {!isGlobalSearch && (
              <HomeTabs
                currentCategory={currentCategory}
                currentVarieties={currentVarieties}
                categoryTabs={categoryTabs}
                setCategoryTabs={(categoryTabs) =>
                  setCategoryTabs(categoryTabs)
                }
                setCurrentCategory={(currentCategory) =>
                  setCurrentCategory(currentCategory)
                }
                setCurrentVarieties={(currentVarieties) =>
                  setCurrentVarieties(currentVarieties)
                }
                layout={layout}
                setLayout={setLayout}
              />
            )}
            {!matches && (
              <CreatePostButton classname={styles.createPostButton} />
            )}
            <SearchBox
              setLayout={setLayout}
              sx={{ m: !isGlobalSearch ? "40px 0" : "0 0 40px" }}
            />
            <Box className={styles.quantity}>{quantity} listings in total</Box>
          </Box>

          {layout.type === TABS_LAYOUT && (
            <>
              <Box
                className={`${styles.postsListWapper} hideScrollbar`}
                sx={{
                  top: isGlobalSearch && "180px !important",
                }}
              >
                <PostsList
                  currentCategory={currentCategory}
                  currentVarieties={currentVarieties}
                  title={title}
                  description={description}
                  setQuantity={setQuantity}
                  loadingSX={{ marginTop: "10vw" }}
                />
                {/* <Footer className={styles.absoluteFooter} /> */}
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default Category;
