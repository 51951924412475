import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  actions as eventActions,
  getEventList,
} from "../../redux/modules/events";

const useEvent = () => {
  const dispatch = useDispatch();
  const eventDispatcher = bindActionCreators(eventActions, dispatch);

  return {
    eventDispatcher: (params) => eventDispatcher.logEvent(params),
    eventGetter: useSelector((state) => getEventList(state)),
  };
};

export default useEvent;
