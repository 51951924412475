import {useState} from "react";

const useWalletModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return{
        isOpen,
        setOpen: (flag) => setIsOpen(flag)
    }
}

export default useWalletModal;