import { Box, CircularProgress } from "@mui/material";

function Loading(props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: props.height ?? "100%",
        ...props.sx,
      }}
    >
      <CircularProgress sx={{ color: "black", ...props.circularProgressSX }} />
    </Box>
  );
}

export default Loading;
