import { combineReducers } from "redux";
import wallet from "./wallet";
import categories from "./categories";
import searchParams from "./searchParams";

const rootReducer = combineReducers({
  wallet,
  categories,
  searchParams,
});

export default rootReducer;
