import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { Box, Grid } from "@mui/material";
import styles from "./index.module.css";
import PostDetailCard from "@/components/PostDetailCard";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import message from "@/components/Message";
import Loading from "@/components/Loading";
import { chainMap, defaultChainId } from "@/config";
import { switchChain, getChain } from "@/redux/modules/wallet";
import { uploadJSON } from "@/utils/upload";
import { Mode } from "@/pages/create-post";
import { listenForTransactionMine } from "@/utils/contract";
import CircularProgress from "@mui/material/CircularProgress";
import abi from "@/abi/postAbi.json";
import useEvent from "@/hooks/aboutEvent/useEvent";

export default function Preview({ item, mode, goBack }) {
  const [loading, setLoading] = useState(false);
  const { eventDispatcher } = useEvent();

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const submit = async (item, mode) => {
    console.log(item);
    if (loading) {
      return;
    }
    setLoading(true);
    const {
      title,
      contacts,
      description,
      price,
      priceNotIncluded,
      images,
      varietyId,
      postId,
      categoryId,
    } = item;

    try {
      const imagesCIDs = images.map((fileObject) => ({
        cid: `ipfs://${fileObject.uri}`,
        type: fileObject.file.type,
        name: fileObject.file.name,
      }));

      const uri = `ipfs://${await uploadJSON({
        ...(contacts && {
          contacts: contacts.map((item) => {
            const { icon, ...rest } = item;
            return rest;
          }),
        }),
        description,
        ...(!priceNotIncluded && { price }),
        ...(imagesCIDs && { images: imagesCIDs }),
      })}`;
      console.log(uri);
      
      let chainId = getChain();
      if (!chainMap[chainId]?.inuse) {
        await switchChain(defaultChainId);
        chainId = defaultChainId;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        chainMap[chainId].postingContract,
        abi,
        signer
      );
      let transactionResponse;
      if (mode === Mode.new || mode === Mode.republish) {
        console.log(categoryId, varietyId, title, uri);
        transactionResponse = await contract.post(
          categoryId === "7" ? categoryId : varietyId,
          title,
          uri
        );
      } else {
        console.log(postId, title, uri);
        transactionResponse = await contract.update(postId, title, uri);
      }
      console.log(transactionResponse);
      await listenForTransactionMine(transactionResponse, provider);
      console.log(`Done Posting`);
      message.success({
        content:
          mode === Mode.new
            ? "Create successfully!"
            : mode === Mode.update
            ? "Update successfully!"
            : "Republish successfully",
      });
      eventDispatcher({
        type: "Your listing",
        title,
        status:
          mode === Mode.new
            ? "has been published"
            : mode === Mode.update
            ? "has been updated"
            : "has been republished",
      });
      nav("/my-posts");
    } catch (error) {
      const err = error.data?.message ?? error.message;
      message.error({ content: err });
    }
    setLoading(false);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.titleBox}>
        <Box className={styles.title}>Preview Your Listing</Box>
      </Box>
      <Box className={styles.postDetailCardContainer}>
        <PostDetailCard
          item={item}
          titleClass={styles.postDetailCardBoxTitle}
          extraInfoClass={styles.postDetailCardBoxExtraInfo}
          postCardClass={styles.postCardClass}
        />
      </Box>
      <Grid
        container
        className={styles.buttonList}
        justifyContent="space-between"
      >
        <Grid item>
          <Box className={`cancelButton`} onClick={() => nav("/my-posts")}>
            Back
          </Box>
        </Grid>
        <Grid item container width="fit-content">
          <Box item className={`cancelButton`} onClick={goBack}>
            Edit
          </Box>
          <Box
            item
            className={`confirmButton ${styles.publishButton}`}
            onClick={() => {
              submit(item, mode);
            }}
            ml={2}
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              <>
                {mode === Mode.new
                  ? "Publish"
                  : mode === Mode.update
                  ? "Republish"
                  : "Republish"}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
