import { cacheExchange, createClient, fetchExchange } from "urql";

export const getCategoryNodes = `
  query {
    categoryNodes {
        id
        name
        parent {
          id
          name
        }
        isRoot
        isLeaf
    }
  }
`;

export const getPostsByCategoryId = `
  query getItems($id: String) {
    postingItems(where: {category_: {id: $id}}) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const getPostsByParentId = `
  query getPostsByParentId($id: String) {
    postingItems(where: { category_: { parent: $id } }) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const getPostsByOwner = `
  query getPostsByOwner($owner: String) {
    postingItems(where: { owner: $owner }) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const getPostByPostId = `
  query getItems($id: String) {
    postingItem(id: $id) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const searchPostsByTitleOrDes = `
  query searchPostsByTitleOrDes($title: String) {
    postingItems(
      where: {
        or: [
          { title_contains_nocase: $title }
        ]
      }
    ) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const searchPostsByTitleInParent = `
  query searchPostsByTitleInParent($id: String, $title: String) {
    postingItems(
      where: { category_: { parent: $id }, title_contains_nocase: $title }
    ) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const searchPostsByTitleInCategory = `
  query searchPostsByTitleInCategory($id: String, $title: String) {
    postingItems( where: {category: $id, title_contains_nocase: $title}) {
      id
      title
      uri
      createdAt
      updatedAt
      expireAt
      owner
      removed
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;

export const createURQLClient = (url) => {
  return new createClient({
    url,
    requestPolicy: "network-only",
    exchanges: [cacheExchange, fetchExchange],
  });
};
