import Box from "@mui/material/Box";
import { useTheme, Link, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import twitterIcon from "@/assets/icon/twitter_black.svg";
import dcIcon from "@/assets/icon/dc_black.svg";
import githubIcon from "@/assets/icon/github_black.svg";

export default function FooterPreview(props) {
  const { window } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Grid container className={`${styles.footer} ${props.className}`}>
      <Box className={styles.copyright}>2023 Ethereum Listing Service</Box>
      <Grid className={styles.linkGrid}>
        <Link
          href={process.env.REACT_APP_TWITTER}
          target="_blank"
          className={styles.link}
        >
          <Box component="img" src={twitterIcon}></Box>
          Twitter
        </Link>
        <Link
          href={process.env.REACT_APP_DC}
          target="_blank"
          className={styles.link}
        >
          <Box component="img" src={dcIcon}></Box>
          Discord
        </Link>
        <Link
          href={process.env.REACT_APP_GITHUB}
          target="_blank"
          className={styles.link}
        >
          <Box component="img" src={githubIcon}></Box>
          Github
        </Link>
      </Grid>
    </Grid>
  );
}
