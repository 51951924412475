import {
  getPostsByOwner,
  getPostsByCategoryId,
  searchPostsByTitleOrDes,
  getPostsByParentId,
  searchPostsByTitleInParent,
  searchPostsByTitleInCategory,
} from "../../graphQL";
import { useQuery } from "urql";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { Grid, Link, Box, Typography } from "@mui/material";
import Loading from "../Loading";
import { useState, useEffect } from "react";
import { deletePost } from "../../pages/create-post";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import message from "../Message";
import usePostURI from "../../hooks/aboutPost/usePostURI";
import styles from "./index.module.css";
import { addressAbbreviation } from "@/utils/format";
import ethIcon from "@/assets/icon/eth.svg";
import moreIcon from "@/assets/icon/more.svg";
import Delete from "@/components/Delete";
import { each } from "lodash";
import Swiper from "../Swiper";
import DefaultImageBox from "../DefaultImageBox";
import { useAliveController } from "react-activation";

const PostsList = ({
  owner,
  currentVarieties,
  currentCategory,
  title = "",
  description = "",
  padding,
  setQuantity,
  removed,
  loadingSX,
}) => {
  const [isMenuOpenIndex, setIsMenuOpenIndex] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  let query;
  let variables;

  if (owner) {
    query = getPostsByOwner;
    variables = { owner };
  } else if ((title || description) === "") {
    if (currentCategory && !currentVarieties) {
      query = getPostsByParentId;
      variables = { id: currentCategory.id };
    } else if (currentVarieties) {
      query = getPostsByCategoryId;
      variables = { id: currentVarieties.id };
    } else {
      query = searchPostsByTitleOrDes;
      variables = { title };
    }
  } else {
    if (currentCategory && !currentVarieties) {
      query = searchPostsByTitleInParent;
      variables = { id: currentCategory.id, title };
    } else if (currentVarieties) {
      query = searchPostsByTitleInCategory;
      variables = { id: currentVarieties.id, title };
    } else {
      query = searchPostsByTitleOrDes;
      variables = { title };
    }
  }

  const res =
    query &&
    variables &&
    useQuery({
      query,
      variables,
    });

  const [resultPosts, reexecuteQuery] = res || [];

  const { data, fetching, error } = resultPosts || {};

  const postingItems = data?.postingItems
    ?.filter((each) => (removed ? each.removed : !each.removed))
    .sort((a, b) => b.updatedAt - a.updatedAt);
  // const height =
  //   location.pathname === "/my-posts"
  //     ? fetching || postingItems?.length === 0 || error
  //       ? "calc(100% - 96px)"
  //       : "auto"
  //     : (title || description) !== ""
  //     ? fetching || postingItems?.length === 0 || error
  //       ? "calc(100% - 84px)"
  //       : "auto"
  //     : currentVarieties
  //     ? fetching || postingItems?.length === 0 || error
  //       ? "calc(100% - 84px)"
  //       : "auto"
  //     : "100%";

  const height =
    location.pathname === "/my-posts"
      ? fetching || postingItems?.length === 0 || error
        ? "calc(100% - 96px)"
        : "auto"
      : fetching || postingItems?.length === 0 || error
      ? "calc(100% - 84px)"
      : "auto";

  useEffect(() => {
    setQuantity(postingItems?.length ?? 0);
  }, [postingItems]);

  return (
    <>
      {!postingItems || fetching ? (
        <Loading height={height} sx={{ ...loadingSX }} />
      ) : !postingItems || postingItems?.length === 0 || error ? (
        <Empty height={height} />
      ) : (
        <Box
          container
          sx={{
            boxSizing: "border-box",
            padding: padding || "0",
            // overflow: "auto",
            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns: `repeat(auto-fill, minmax(${
              owner ? "354px" : "300px"
            },1fr))`,
            justifyItems: "center",
            gridGap: "32px",
            height,
            minHeight: "calc(100% - 191px)",
            marginBottom: "107px",
            // padding: "16px 0",
            ...((postingItems?.length === 0 || error) && {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
          }}
        >
          {postingItems?.map((each, index) => {
            return (
              <CommonPost
                each={each}
                index={index}
                isMyPost={!!owner}
                isMenuOpenIndex={isMenuOpenIndex}
                setIsMenuOpenIndex={setIsMenuOpenIndex}
                setDeleteIndex={setDeleteIndex}
                removed={removed}
              />
            );
          })}
          <Delete
            each={postingItems[deleteIndex]}
            isOpen={deleteIndex >= 0}
            setOpen={setDeleteIndex}
            reexecuteQuery={reexecuteQuery}
          ></Delete>
        </Box>
      )}
    </>
  );
};

const CommonPost = ({
  each,
  index,
  isMyPost,
  isMenuOpenIndex,
  setIsMenuOpenIndex,
  setDeleteIndex,
  removed,
}) => {
  const postURIRes = usePostURI(each?.uri);
  const nav = useNavigate();
  const { drop, dropScope, clear, getCachingNodes } = useAliveController();

  const closeMenu = () => {
    setIsMenuOpenIndex(-1);
  };

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  return (
    <Grid item key={each.id} width={"100%"}>
      <Box
        className={styles.postCard}
        onClick={() => {
          nav(
            `/category/${each?.category.parent.id}/variety/${each?.category.id}/post/${each.id}`
          );
        }}
      >
        <Box sx={{ height: "354px", position: "relative" }}>
          {!postURIRes?.images?.[0] ? (
            <DefaultImageBox />
          ) : (
            // <Box
            //   component="img"
            //   sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            //   src={`${process.env.REACT_APP_PinataGateWay}${postURIRes.images[0]}`}
            // ></Box>
            <Swiper
              images={postURIRes.images.map(({ uri, file }) => ({
                upload: `${process.env.REACT_APP_PinataGateWay}${uri}`,
                uri,
                file,
              }))}
            />
          )}
          {isMyPost ? (
            <>
              {/* <Box
                component="img"
                className={styles.eth}
                src={ethIcon}
                sx={{ left: "8px", top: "8px" }}
              ></Box> */}
              <Box
                className={styles.moreBox}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpenIndex(index);
                }}
              >
                <Box
                  // component="img"
                  className={styles.more}
                  // src={moreIcon}
                ></Box>
                {index === isMenuOpenIndex && (
                  <Box className={styles.menuContainer}>
                    <Box
                      // component={RouterLink}
                      className={styles.option}
                      sx={{ borderBottom: "1px solid #F7F7F7" }}
                      // to={`/${each.owner}/post/${
                      //   removed ? "republish" : "update"
                      // }/category/${each.category.parent.id}/variety/${
                      //   each.category.id
                      // }/post/${each.id}`}
                      onClick={async () => {
                        // await clear();
                        await drop("post");
                        nav(
                          `/${each.owner}/post/${
                            removed ? "republish" : "update"
                          }/category/${each?.category.parent.id}/variety/${
                            each?.category.id
                          }/post/${each.id}`
                        );
                      }}
                    >
                      Edit
                    </Box>
                    {!removed && (
                      <Box
                        className={styles.option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteIndex(index);
                          setIsMenuOpenIndex(-1);
                        }}
                      >
                        Delete
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <></>
            // <Box component="img" className={styles.eth} src={ethIcon}></Box>
          )}
        </Box>
        <Box
          sx={{
            padding: "16px",
          }}
        >
          <Box className={styles.title}>{each.title}</Box>
          <Typography
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: postURIRes?.description ?? "" }}
          ></Typography>
        </Box>
        <Grid className={styles.extraInfo}>
          <Box
            sx={{ borderRight: "1px solid #141414" }}
            className={styles.item}
          >
            {addressAbbreviation(each.owner)}
          </Box>
          <Box className={styles.item}>
            {new Date(Number(`${each.updatedAt}000`))
              .toDateString()
              .split(" ")
              .slice(1, -1)
              .join(" ") +
              ", " +
              new Date(Number(`${each.updatedAt}000`)).getUTCFullYear()}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

const Empty = ({ height }) => {
  return (
    <Box
      sx={{
        height: height ?? "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      No posts found
    </Box>
  );
};

export default PostsList;
