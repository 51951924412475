/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/

import { getChain, types } from "../wallet";

const initialState = {
  address: null,
  chainId: null,
  allowance: null,
};

export const schema = {
  name: "wallet",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIGURE_WALLET:
      return {
        ...state,
        address: action.payload.address,
        chainId: action.payload.chain,
      };
    case types.SWITCH_CHAIN:
      return {
        ...state,
        chainId: action.payload.chain,
      };
    case types.WALLET_CONNECT:
      return {
        ...state,
        address: action.payload.address,
        chainId: action.payload.chain,
      };
    case types.SWITCH_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
      };
    case types.CHECK_ALLOWANCE:
      return { ...state, allowance: action.payload };
    case types.MAKE_APPROVE:
      return { ...state, allowance: action.payload };
    case types.WALLET_DISCONNECT:
      return { ...initialState, chainId: getChain() };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getWallet = (state) => {
  return state.entities.wallet;
};
