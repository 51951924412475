import { combineReducers } from "redux";
import app from "./app";
import entities from "./entities";
import wallet from "./wallet";
import categories from "./categories";
import events from "./events";

const rootReducer = combineReducers({
  app,
  wallet,
  entities,
  categories,
  events,
});

export default rootReducer;
