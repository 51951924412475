import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as categoryActions} from "../../redux/modules/categories";
import {getCategories} from "../../redux/modules/entities/categories";

const useCategory = () =>{

    const dispatch = useDispatch();
    const categoryDispatcher = bindActionCreators(
        categoryActions,
        dispatch
    );

    return {
        categoryFetcher: async (type, data)=> await categoryDispatcher.fetchCategories(type, data),
        categoriesGetter: useSelector((state) => getCategories(state))
    }

}

export default useCategory;