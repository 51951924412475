import { useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import PreviewComponent from "@/components/Preview";

export default function Preview() {
  const nav = useNavigate();
  const loc = useLocation();
  const { item, mode } = loc.state;
  const goBack = () => {
    nav(-1);
  };

  // useEffect(() => {
  //   if (location.pathname.includes("/preview")) {
  //     nav(location.pathname.replace("/preview", ""));
  //   }
  // }, []);

  return (
    <PreviewComponent
      item={item}
      mode={mode}
      goBack={eval(goBack)}
    ></PreviewComponent>
  );
}
