import React, { lazy, Suspense } from "react";
import { KeepAlive } from "react-activation";
import DashboardLayout from "./layout/DashboardLayout";
import LoadingScreen from "./utils/LoadingScreen";
import Dashboard from "./pages/dashboard";
import PreviewDashboard from "./pages/preview-dashboard";
import ProductCreateForm from "./pages/create-post";
import Preview from "./pages/preview";
// import AuthGuard from "./components/AuthGuard";
import PostDetail from "./pages/post-detail";
import MyPosts from "./pages/my-posts";
import MyHistory from "./pages/my-history";
import Dao from "./pages/dao";
import Search from "./pages/search";
import { createBrowserRouter } from "react-router-dom";
import Category from "./pages/category";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// const Dashboard =  Loadable(() => import("./pages/dashboard" ));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/preview-dashboard",
        element: <PreviewDashboard />,
      },
      {
        path: "/category/:category",
        element: <Category />,
      },
      {
        path: "/category/:category/variety/:variety",
        element: <Category />,
      },
      {
        path: "/search",
        element: <Category />,
      },
      {
        path: "/category/:category/variety/:variety/post/:postId",
        element: <PostDetail />,
      },
      {
        path: "/:userAddress/post/new",
        element: (
          <KeepAlive cacheKey={location.pathname} name="post">
            <ProductCreateForm />
          </KeepAlive>
        ),
      },
      {
        path: "/:userAddress/post/update/category/:category/variety/:variety/post/:postId",
        element: (
          <KeepAlive cacheKey={location.pathname} name="post">
            <ProductCreateForm />
          </KeepAlive>
        ),
      },
      {
        path: "/:userAddress/post/republish/category/:category/variety/:variety/post/:postId",
        element: (
          <KeepAlive cacheKey={location.pathname} name="post">
            <ProductCreateForm />
          </KeepAlive>
        ),
      },
      {
        path: "/:userAddress/post/new/preview",
        element: <Preview />,
      },
      {
        path: "/:userAddress/post/update/category/:category/variety/:variety/post/:postId/preview",
        element: <Preview />,
      },
      {
        path: "/:userAddress/post/republish/category/:category/variety/:variety/post/:postId/preview",
        element: <Preview />,
      },
      {
        path: "/my-posts",
        element: <MyPosts />,
      },
      {
        path: "/my-history",
        element: <MyHistory />,
      },
      {
        path: "/dao",
        element: <Dao />,
      },
      // {
      //   path: "/search",
      //   element: <Search />,
      // },
    ],
  },
];

export default createBrowserRouter(routes);
