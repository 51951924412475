import { Grid, Box, Link } from "@mui/material";
import docSvg from "../../assets/icon/doc.svg";
import tallySvg from "../../assets/icon/tally.svg";
import dcSvg from "../../assets/icon/dc.svg";
import styles from "./index.module.css";
import { repeat } from "lodash";
import Footer from "@/components/Footer";

const daoList = [
  {
    text: "Chat on Discord",
    image: dcSvg,
    link: "https://discord.gg/bT4sRdXTr9",
    background: "#EBF8FF",
    color: "#686DEE",
    border: "1px solid rgba(104, 109, 238, 0.5)",
  },
  {
    text: "Vote on Tally",
    image: tallySvg,
    link: "https://www.tally.xyz/gov/els-dao-test2",
    background: "#FFFFF0",
    color: "#13CBB7",
    border: "1px solid rgba(0, 220, 196, 0.5)",
  },
  {
    text: "Documentation",
    image: docSvg,
    link: "https://els-ethereum-listing-service.gitbook.io/untitled/",
    background: "#F0FFF4",
    color: "#5AA5EA",
    border: "1px solid rgba(90, 165, 234, 0.5)",
  },
];

const Dao = () => {
  return (
    <Grid className={styles.container}>
      <Box
        sx={{
          fontFamily: "Satoshi-Regular",
          fontSize: "12px",
          lineHeight: "100%",
          color: "#333",
          m: "0 24px 0",
        }}
        className={styles.title}
      >
        Join the ELS governance community. <br />
        Join the conversation.
      </Box>
      {/* <Box
        sx={{
          borderBottom: "1px solid #999",
          m: "11px 24px 0",
        }}
      ></Box> */}
      <Grid
        sx={{
          display: "grid",
          justifyContent: "space-between",
          gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
          justifyItems: "center",
          gridGap: "27px",
          overflow: "auto",
          p: "4vw 1vw",
        }}
        className={styles.daoWrapper}
      >
        {daoList.map((dao) => (
          <DaoBox props={dao}></DaoBox>
        ))}
      </Grid>
      <Footer className={styles.absoluteFooter} />
    </Grid>
  );
};

const DaoBox = ({
  props: { text, image, link, background, color, border },
}) => {
  return (
    <Box className={styles.cardBoxWrapper}>
      <Box className={styles.cardBox}>
        <Link
          href={link}
          target="_blank"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0",
            background,
            color,
            textDecoration: "unset",
          }}
          className={styles.card}
        >
          <Box component="img" src={image} className={styles.image}></Box>
          <Box className={styles.text} component="text">
            {text}
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Dao;
