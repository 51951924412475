import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import useTheme from "@mui/material/styles/useTheme";
import useWallet from "../../hooks/aboutWallet/useWallet";
import useWalletModal from "../../hooks/aboutWallet/useWalletModal";
import styles from "./index.module.css";
import { Connector, useConnect, useDisconnect, useNetwork } from "wagmi";
import arrowDownIcon from "@/assets/icon/arrowDown.svg";
import disconnectIcon from "@/assets/icon/disconnect.svg";
import dashboardIcon from "@/assets/icon/dashboard2.svg";
import { addressAbbreviation } from "@/utils/format";
import metaMaskConnectIcon from "@/icons/MetamaskConnect";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { getChain } from "@/redux/modules/wallet";
import message from "@/components/Message";
import Cookies from "js-cookie";

const BaseButton = ({ isMenuOpen, setIsMenuOpen }) => {
  const { walletGetter, walletSetter } = useWallet();

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
      }}
      className={`${!isMenuOpen ? styles.container : styles.baseButton} `}
    >
      <Box
        component={"img"}
        src={metaMaskConnectIcon}
        sx={{ height: 28, width: 28 }}
      ></Box>
      <Box className={styles.address}>
        {addressAbbreviation(walletGetter.address)}
      </Box>
      <Box
        component={"img"}
        src={arrowDownIcon}
        sx={{
          transform: isMenuOpen && "rotate(180deg)",
          transition: "all 0.2s",
        }}
      ></Box>
    </Box>
  );
};

const WalletConnect = ({ disabled = false }) => {
  const theme = useTheme();

  const { walletGetter, walletSetter, walletDisconnetor } = useWallet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { connectAsync, connectors } = useConnect();
  const nav = useNavigate();
  const { disconnect } = useDisconnect();

  const handleWalletConnect = async (connector) => {
    if (typeof window.ethereum === "undefined") {
      message.warning({ content: "Please install MetaMask" });
      return;
    }
    try {
      const { account } = await connectAsync({ connector });
      const chain = getChain();
      console.log(`[WalletConnect]: account ${account}, chain: ${chain}`);
      walletSetter({ address: account?.toLowerCase(), chain: getChain() });
      closeMenu();
      localStorage.setItem("DisconnectWallet", "false");
    } catch (error) {
      console.error(error);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleWalletDisConnect = () => {
    disconnect();
    walletDisconnetor();
    closeMenu();
    nav("/");
    localStorage.setItem("DisconnectWallet", "true");
    if (window.okxwallet && Cookies.get("selectWallet")) {
      Cookies.remove("selectWallet");
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  return (
    <>
      <Box
      // className={styles.container}
      >
        {!disabled && walletGetter?.address ? (
          <Box
            className={`${isMenuOpen && styles.container} ${
              isMenuOpen && styles.menuContainer
            } `}
          >
            <BaseButton
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            ></BaseButton>
            {isMenuOpen && (
              <>
                <Box
                  component={RouterLink}
                  className={styles.option}
                  sx={{ borderBottom: "1px solid #F7F7F7" }}
                  to={"/my-posts"}
                >
                  <Box component={"img"} src={dashboardIcon}></Box>
                  My Dashboard
                </Box>
                <Box
                  className={styles.option}
                  onClick={() => {
                    handleWalletDisConnect();
                  }}
                >
                  <Box
                    component={"img"}
                    src={disconnectIcon}
                    sx={{ marginRight: "8px !important" }}
                  ></Box>
                  Disconnect Wallet
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box
            className={`${styles.container} ${
              !disabled && styles.connectWallet
            }`}
            sx={{ cursor: disabled && "auto" }}
            onClick={(e) => {
              if (disabled) {
                return;
              }
              e.stopPropagation();
              handleWalletConnect(connectors[0]);
            }}
          >
            Connect Wallet
          </Box>
        )}
      </Box>
    </>
  );
};

export default WalletConnect;
