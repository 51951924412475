import ImageUploading from "react-images-uploading";
import { nanoid } from "nanoid";
import { Box } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CircularProgress from "@mui/material/CircularProgress";
import Cancel from "@mui/icons-material/Cancel";
import message from "../../components/Message";
import {
  getCIDMetadata,
  uploadDirectory,
  uploadFile,
} from "../../utils/upload";
import uploadSvg from "../../assets/icon/upload.svg";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { compress } from "@/utils/compress";
import fileIcon from "@/assets/icon/file.svg";
import { UploadType } from ".";

const UploadStatus = {
  pending: "pending",
  success: "success",
  failed: "failed",
};

const handleUploadDirectory = ({
  index,
  images,
  setImages,
  removedImagesList,
}) => {
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = "multiple";
  input.accept = "*/*";
  input.setAttribute("webkitdirectory", "");
  input.setAttribute("directory", "");
  document.body.appendChild(input);
  input.click();
  document.body.removeChild(input);
  input.addEventListener("change", (e) =>
    onDirectoryUpload({ e, index, images, setImages, removedImagesList })
  );
};

export const onDirectoryUpload = async ({
  e,
  index,
  images,
  setImages,
  removedImagesList,
}) => {
  if (index === undefined && images.length === 6) {
    message.warning({
      content: "Up to 6 files or directories can be uploaded",
    });
    return;
  }
  const directory = {
    id: nanoid(),
    uploadStatus: UploadStatus.pending,
    file: {
      type: "directory",
      name:
        e.target.files?.[0]?.webkitRelativePath?.split("/")?.[0] || "directory",
    },
  };
  let imagesList;
  if (index) {
    images.splice(index, 1, directory);
    imagesList = [...images];
  } else {
    imagesList = [...images, directory];
  }
  setImages(imagesList);
  try {
    const uri = await uploadDirectory(e.target.files);

    const indexList = [];
    imagesList.forEach((fileObject, index) => {
      if (removedImagesList.includes(fileObject.id)) {
        indexList.unshift(index);
      }
    });
    indexList.forEach((index) => {
      imagesList.splice(index, 1);
    });
    directory.uri = uri;
    directory.upload = `${process.env.REACT_APP_PinataGateWay}${uri}`;
    directory.uploadStatus = UploadStatus.success;
  } catch (error) {
    console.log(error);
    directory.uploadStatus = UploadStatus.failed;
    message.error({
      content: "Upload failed",
    });
  }
  setImages([...imagesList]);
};

export default function ImageUploader({
  children,
  imageHeight,
  images,
  setImages,
  removedImagesList,
  uploadType,
}) {
  return (
    <ImageUploading
      allowNonImageType
      label="Upload files or directories"
      multiple
      value={images}
      maxNumber={6}
      onChange={async (imagesList, addUpdatedIndex) => {
        if (!addUpdatedIndex) {
          setImages(imagesList);
          return;
        }
        addUpdatedIndex.forEach(async (index) => {
          const image = imagesList[index];
          image.id = nanoid();
          image.uploadStatus = UploadStatus.pending;
          setImages(imagesList);
          try {
            let blobFile = { blob: image.file, name: image.file.name };
            if (image.file?.type?.includes?.("image")) {
              blobFile = await compress(image.file);
            }
            const uri = await uploadFile(blobFile);
            const indexList = [];
            imagesList.forEach((fileObject, index) => {
              if (removedImagesList.includes(fileObject.id)) {
                indexList.unshift(index);
              }
            });
            indexList.forEach((index) => {
              imagesList.splice(index, 1);
            });
            image.uri = uri;
            image.uploadStatus = UploadStatus.success;
          } catch (error) {
            console.log(error);
            image.uploadStatus = UploadStatus.failed;
          }
          setImages([...imagesList]);
        });
      }}
      onError={(error) => {
        if (error?.maxNumber) {
          message.warning({
            content: "Up to 6 files or directories can be uploaded",
          });
        } else {
          message.error({
            content: "Upload failed",
          });
        }
      }}
      dataURLKey="upload"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => {
        const onUpload = () => {
          if (uploadType === UploadType.directory) {
            handleUploadDirectory({ images, setImages, removedImagesList });
          } else {
            onImageUpload();
          }
        };

        return (
          <Box
            sx={{
              display: "grid",
              justifyContent: "space-between",
              gridTemplateColumns: "repeat(auto-fill, minmax(102px,1fr))",
              gridGap: "16px",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // width: "122px",
                height: imageHeight,
                cursor: "pointer",
                background: "#FFFFFF",
              }}
              onClick={onImageUpload}
            >
              <Box component={"img"} src={uploadSvg} />
              <Box
                sx={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "16px",
                  mt: 2,
                  color: "#999",
                }}
              >
                Upload Image
              </Box>
            </Box> */}
            <Box onClick={onUpload} {...dragProps}>
              {children}
            </Box>
            <>
              {imageList.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    // height: imageHeight,
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "100%",
                      position: "relative",
                      background:
                        !image.file?.type?.includes?.("image") && "#fbfafd",
                      cursor: "pointer",
                    }}
                  >
                    {(image.uploadStatus === UploadStatus.pending ||
                      image.uploadStatus === UploadStatus.failed) && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          zIndex: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backdropFilter: "blur(2px)",
                          background: "rgba(255,255,255,0.4)",
                          cursor:
                            image.uploadStatus === UploadStatus.failed &&
                            "pointer",
                        }}
                        onClick={() => {
                          if (image.uploadStatus === UploadStatus.pending) {
                            return;
                          }
                          onImageUpdate(index);
                        }}
                      >
                        {image.uploadStatus === UploadStatus.pending && (
                          <CircularProgress sx={{ color: "black" }} size={30} />
                        )}
                        {image.uploadStatus === UploadStatus.failed && (
                          <ErrorOutlineRoundedIcon
                            sx={{
                              color: "red",
                              fontSize: "36px",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    <Cancel
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onClick={() => {
                        onImageRemove(index);
                        removedImagesList.push(image.id);
                      }}
                    />
                    <Box
                      component="img"
                      src={
                        image.file?.type?.includes?.("image")
                          ? image.upload
                          : fileIcon
                      }
                      sx={{
                        position: "absolute",
                        width: image.file?.type?.includes?.("image")
                          ? "100%"
                          : "50%",
                        height: image.file?.type?.includes?.("image")
                          ? "100%"
                          : "50%",
                        objectFit: "cover",
                        background: "rgba(255,255,255,0.4)",
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: "auto",
                        borderRadius:
                          !image.file?.type?.includes?.("image") && "50%",
                      }}
                      onClick={() => {
                        if (image.uploadStatus === UploadStatus.pending) {
                          return;
                        }
                        if (uploadType === UploadType.directory) {
                          handleUploadDirectory({
                            index,
                            images,
                            setImages,
                            removedImagesList,
                          });
                        } else {
                          onImageUpdate(index);
                        }
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </>
          </Box>
        );
      }}
    </ImageUploading>
  );
}
