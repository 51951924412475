/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const types = {
  SEARCH: "APP|SEARCH",
};

const initialState = {
  title: "",
  description: "",
};

export const actions = {
  search: (title, description) => {
    return async (dispatch, getState) => {
      await dispatch({
        type: types.SEARCH,
        payload: { title, description },
      });
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getSearchParams = (state) => {
  return state.entities.searchParams;
};
