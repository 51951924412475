export const getElsTokenAddress = () =>
  "0x511eB1B112f60Fe92f9DE409F6803257a223378e";
export const getTimelockController = () =>
  "0x62f4CC4f568d72171C4442867A764a03E29e7144";
export const getELSGovernor = () =>
  "0x62f4CC4f568d72171C4442867A764a03E29e7144";
export const getListing = () => "0x2a9fbA5926B52a3b8d0F9e41A6f2E4e7e8c7366B";
export const getELSFausetAddress = () =>
  "0x1c8cd38865ab14552304a10aC3b609D2de902511";
export const getPostingAddress = () =>
  "0xD420595CF653C47d0d8De3453E0dFbf1225D2c88";
export const getlistingAddress = () =>
  "0x9448219ce99db80fc0c993649F35408d77869Ba6";

export function listenForTransactionMine(transactionResponse, provider) {
  console.log(`Mining: ${transactionResponse.hash} `);
  return new Promise((resolve, reject) => {
    provider.once(transactionResponse.hash, (transactionReceipt) => {
      console.log(
        `complete with ${transactionReceipt.confirmations} confirmation `
      );
      resolve(transactionReceipt);
    });
  });
}
