import { createStore, applyMiddleware,  } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { fetchAPI, postAPI, viewContract } from "./middlewares/api";
import rootReducer from "./modules";

let store;

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["events"],
  },
  rootReducer
);

if (
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk, fetchAPI, postAPI, viewContract))
  );
} else {
  store = createStore(
    persistedReducer,
    applyMiddleware(thunk, fetchAPI, postAPI, viewContract)
  );
}

export default { store, persistor: persistStore(store) };
