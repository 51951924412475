import axios from "axios";

export const uploadFile = async (blobFile) => {
  const url = `${process.env.REACT_APP_PinataAPI}pinning/pinFileToIPFS`;
  const formData = new FormData();
  formData.append("file", blobFile.blob);
  const res = await axios.post(url, formData, {
    headers: {
      pinata_api_key: process.env.REACT_APP_PinataApiKey,
      pinata_secret_api_key: process.env.REACT_APP_PinataSecretApiKey,
    },
  });
  return res.data.IpfsHash;
};

export const uploadDirectory = async (files) => {
  const url = `${process.env.REACT_APP_PinataAPI}pinning/pinFileToIPFS`;
  const formData = new FormData();
  for (const file of files) {
    formData.append(`file`, file);
  }
  const res = await axios.post(url, formData, {
    headers: {
      pinata_api_key: process.env.REACT_APP_PinataApiKey,
      pinata_secret_api_key: process.env.REACT_APP_PinataSecretApiKey,
    },
  });
  return res.data.IpfsHash;
};

export const uploadJSON = async (object) => {
  const url = `${process.env.REACT_APP_PinataAPI}pinning/pinJSONToIPFS`;
  const res = await axios.post(url, object, {
    headers: {
      pinata_api_key: process.env.REACT_APP_PinataApiKey,
      pinata_secret_api_key: process.env.REACT_APP_PinataSecretApiKey,
    },
  });
  return res.data.IpfsHash;
};

export const getCIDName = async (cid) => {
  const url = `${process.env.REACT_APP_PinataAPI}data/pinList?cid=${cid}`;
  const res = await axios.get(url, {
    headers: {
      pinata_api_key: process.env.REACT_APP_PinataApiKey,
      pinata_secret_api_key: process.env.REACT_APP_PinataSecretApiKey,
    },
  });
  return res?.data?.rows?.[0]?.metadata?.name;
};