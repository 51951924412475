import arrowHover from "@/assets/icon/arrowHover.svg";
import arrowLeftHover from "@/assets/icon/arrowLeftHover.svg";
import arrowRightHover from "@/assets/icon/arrowRightHover.svg";
import notificationHover from "@/assets/icon/notificationHover.svg";
import notificationActive from "@/assets/icon/notificationActive.svg";
import notificationDotHover from "@/assets/icon/notificationDotHover.svg";
import notificationDotActive from "@/assets/icon/notificationDotActive.svg";
import shareHover from "@/assets/icon/shareHover.svg";
import shareActive from "@/assets/icon/shareActive.svg";
import moreHover from "@/assets/icon/moreHover.svg";
import moreActive from "@/assets/icon/moreActive.svg";
import { Box } from "@mui/material";

const list = [
  arrowHover,
  arrowLeftHover,
  arrowRightHover,
  notificationHover,
  notificationActive,
  notificationDotHover,
  notificationDotActive,
  shareHover,
  shareActive,
  moreHover,
  moreActive,
];

export default function LoadImages() {
  return (
    <>
      {list.map((el) => (
        <Box
          component="img"
          src={el}
          sx={{
            display: "none",
          }}
        />
      ))}
    </>
  );
}
