import { Box, Button, Grid, Link } from "@mui/material";
import useWallet from "../../hooks/aboutWallet/useWallet";
import PostsList from "../../components/PostsList";
import { useEffect, useState } from "react";
import useWalletModal from "../../hooks/aboutWallet/useWalletModal";
import Sidebar from "@/components/Sidebar";
import styles from "./index.module.css";
import { useNavigate, NavLink } from "react-router-dom";

const MyHistory = () => {
  const { walletGetter } = useWallet();
  const { isOpen, setOpen } = useWalletModal();
  const [quantity, setQuantity] = useState(0);
  const nav = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!walletGetter.address) {
      handleClickOpen();
    } else {
      setOpen(false);
    }
  }, [walletGetter.address]);

  return (
    <>
      <Sidebar></Sidebar>
      <Box className={styles.rightContainer}>
        <Grid
          item
          container
          // justifyContent="space-between"
          alignContent="center"
        >
          <Box item className={styles.title}>
            My History
          </Box>
        </Grid>
        <Box className={styles.active}>Archived Listings</Box>
        <Box className={styles.quantity}>{quantity} listings in total</Box>
        {walletGetter.address && (
          <PostsList
            owner={walletGetter.address}
            padding={0}
            setQuantity={setQuantity}
            removed
          />
        )}
        {/* <WalletConnectModal isOpen={isOpen} setOpen={setOpen} /> */}
      </Box>
    </>
  );
};

export default MyHistory;
