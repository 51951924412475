import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "./theme";
import { THEMES } from "./theme/constants";
import { useNavigate } from "react-router-dom";
import routes from "./routes";
import useWallet from "./hooks/aboutWallet/useWallet";
import { useQuery } from "urql";
import { getCategoryNodes } from "./graphQL";
import { types } from "./redux/modules/categories";
import useCategory from "./hooks/aboutCategory/useCategory";
import { getChain } from "./redux/modules/wallet";
import LoadImages from "./components/LoadImages";
import { chainMap, defaultChainId } from "@/config";

function transformData(input) {
  const { categoryNodes } = input;
  let output = [];
  const rootMap = new Map();
  const pendingChildren = new Map();

  const chainConfig = chainMap[getChain()];
  const currentChainId = chainConfig?.inuse ? getChain() : defaultChainId;

  categoryNodes.forEach((node, index) => {
    if (node.isRoot) {
      const entry = {
        id: node.id,
        index: rootMap.size,
        label:
          currentChainId === defaultChainId
            ? node.name
            : node.name.charAt(0).toUpperCase() + node.name.slice(1),
        name:
          currentChainId === defaultChainId
            ? node.name
            : node.name.toUpperCase(),
        value:
          currentChainId === defaultChainId
            ? node.name
            : node.name.toUpperCase(),
        varieties: [],
      };

      // Add pending children if they exist
      if (pendingChildren.has(node.id)) {
        entry.varieties.push(...pendingChildren.get(node.id));
        pendingChildren.delete(node.id);
      }

      rootMap.set(node.id, entry);
      output.push(entry);
    } else {
      const parentEntry = rootMap.get(node.parent.id);

      const childEntry = {
        id: node.id,
        label:
          currentChainId === defaultChainId
            ? node.name
            : node.name.charAt(0).toUpperCase() + node.name.slice(1),
        name:
          currentChainId === defaultChainId
            ? node.name
            : node.name.toUpperCase(),
        value:
          currentChainId === defaultChainId
            ? node.name
            : node.name.toUpperCase(),
      };

      if (parentEntry) {
        childEntry.index = parentEntry.varieties.length;
        parentEntry.varieties.push(childEntry);
      } else {
        if (!pendingChildren.has(node.parent.id)) {
          pendingChildren.set(node.parent.id, []);
        }
        childEntry.index = pendingChildren.get(node.parent.id).length;
        pendingChildren.get(node.parent.id).push(childEntry);
      }
    }
  });

  output = output.map((category) => {
    category.varieties = category.varieties.sort(
      (a, b) => Number(a.id) - Number(b.id)
    );
    return category;
  });

  return output;
}

const theme = createCustomTheme({
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: false,
  theme: THEMES.LIGHT,
});

function App() {
  const nav = useNavigate();
  const { walletConfigurator, allowanceChecker, walletGetter, setChain } =
    useWallet();
  const { categoryFetcher } = useCategory();
  const [resultCategories] = useQuery({ query: getCategoryNodes });
  const { data, fetching, error } = resultCategories;
  const [lock, setLock] = useState(false);

  useEffect(() => {
    if (fetching) {
      categoryFetcher(types.CATEGORY_FETCHER.request());
    }
    if (!fetching && data) {
      const transformedData = transformData(data);
      categoryFetcher(types.CATEGORY_FETCHER.success(), transformedData);
    }

    if (!fetching && error) {
      categoryFetcher(types.CATEGORY_FETCHER.failure(), error);
    }
  }, [fetching]);

  useEffect(() => {
    if (lock || self !== top) {
      return;
    }
    if (
      walletGetter.address &&
      !/\/category\/\d+?\/variety\/\d+?\/post\/\d+?/.test(location.pathname) &&
      location.pathname.match(/\/(.*?)\//)?.[1] !== walletGetter.address
    ) {
      nav("/my-posts");
      setLock(true);
      // nav(`${walletGetter.address}/post/new`);
      // allowanceChecker();
    }
    if (
      !walletGetter.address &&
      (location.pathname === "/my-posts" || location.pathname === "/my-history")
    ) {
      nav("/");
    }
    setTimeout(() => {
      setLock(true);
    }, 1000);
  }, [walletGetter.address, lock]);

  useEffect(() => {
    if (localStorage.getItem("DisconnectWallet") === "true") {
      return;
    }
    walletConfigurator().then(async () => {
      setChain(getChain());
    });
  }, []);

  return (
    <div className="App">
      <LoadImages />
      <ThemeProvider theme={theme}></ThemeProvider>
      {/* <RouterProvider router={routes} /> */}
    </div>
  );
}

export default App;
