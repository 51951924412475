import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import styles from "./index.module.css";
import message from "../Message";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChooseContact({
  isOpen,
  setOpen,
  savedData,
  handleSavedData,
}) {
  const [selectValue, setSelectValue] = useState();
  const [inputValue, setInputValue] = useState();

  const handleSelectChange = (e) => {
    setSelectValue(e.target.options[e.target.selectedIndex].value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (!selectValue) {
      message.warning({ content: "Please choose a contact method" });
      return;
    }
    if (!inputValue) {
      message.warning({ content: "Please fill in the Handle" });
      return;
    }
    if (savedData.find((item) => item.key === selectValue)) {
      message.warning({
        content: `${selectValue} already exists, please choose another`,
      });
      return;
    }
    handleSavedData({ selectValue, inputValue });
    setOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectValue("");
      setInputValue("");
    }
  }, [isOpen]);

  return (
    <Dialog
      maxWidth={"md"}
      //   fullWidth
      //   sx={{
      //     backdropFilter: "blur(5px)",
      //   }}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className={styles.dialog}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "unset",
        },
      }}
    >
      <Box className={styles.dialogContainer}>
        <DialogContent className={styles.dialogContent}>
          <Box>Choose a Contact Method</Box>
          <select
            fullWidth
            label="Variety"
            name="variety"
            onChange={handleSelectChange}
            select
            SelectProps={{ native: true }}
            value={selectValue}
            variant="standard"
            className={styles.select}
          >
            <option selected disabled hidden value={""}>
              Please choose
            </option>
            {["Email", "Twitter", "Discord", "Github"].map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <input
            type="text"
            sx={{ margin: "16px" }}
            placeholder="Handle"
            value={inputValue}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Box onClick={handleClose} className="cancelButton">
            Cancel
          </Box>
          <Box onClick={handleSave} className="confirmButton">
            Save
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
