import ImagesQuicklyCompress from "images-quickly-compress";

export async function compress(file) {
  try {
    const imageCompress = new ImagesQuicklyCompress({
      mode: "pixel",
      num: 720000,
      size: "500kb",
      imageType: "image/jpeg",
      quality: 0.8,
      orientation: false,
    });
    const res = await imageCompress.compressor([file]);
    return { blob: res[0], name: file.name };
  } catch (error) {
    console.error(error);
    return { blob: file, name: file.name };
  }
}
