import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import { useQuery } from "urql";
import { ethers } from "ethers";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  ImageList,
  ImageListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AddOutlined from "@mui/icons-material/AddOutlined";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CircularProgress from "@mui/material/CircularProgress";
import Cancel from "@mui/icons-material/Cancel";
import QuillEditor from "../../components/QuillEditor";
import useCategory from "../../hooks/aboutCategory/useCategory";
import useWallet from "../../hooks/aboutWallet/useWallet";
import { useLocation, useNavigate } from "react-router-dom";
import message from "../../components/Message";
import Loading from "../../components/Loading";
import ImageUploading from "react-images-uploading";
import { uploadDirectory, uploadJSON } from "../../utils/upload";
import uploadSvg from "../../assets/icon/upload.svg";
import { getPostByPostId } from "../../graphQL";
import { nanoid } from "nanoid";
import usePostURI from "../../hooks/aboutPost/usePostURI";
import { switchChain, getChain } from "../../redux/modules/wallet";
import { chainMap, defaultChainId } from "../../config";
import styles from "./index.module.css";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@/components/Checkbox";
import uploadImgIcon from "@/assets/icon/uploadImg.svg";
import ImageUploader, { onDirectoryUpload } from "./ImageUploader";
import ChipsArray from "@/components/ChipsArray";
import ChooseContact from "@/components/ChooseContact";
import Preview from "@/components/Preview";

export const Mode = {
  new: "new",
  update: "update",
  republish: "republish",
};

const PreviewMode = {
  preview: "preview",
  previewBack: "previewBack",
};

const UploadStatus = {
  pending: "pending",
  success: "success",
  failed: "failed",
};

export const UploadType = {
  file: "file",
  directory: "directory",
};

const ProductCreateForm = () => {
  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState([]);
  // const [previewMode, setPreviewMode] = useState(PreviewMode.Preview);
  const [locationChanged, setLocationChanged] = useState(false);
  const [postId, setPostId] = useState(0);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [categoryId, setCategoryId] = useState(-1);
  const [varietyId, setVarietyId] = useState(-1);
  const [isDeletingPost, setIsDeletingPost] = useState(0);
  const [images, setImages] = useState([]);
  const [chooseContactOpen, setChooseContactOpen] = useState(false);
  // const [isPreviewMode, setIsPreviewMode] = useState();
  const [previewItem, setPreviewItem] = useState();
  const [postURIIsLoaded, setPostURIIsLoaded] = useState(false);
  const [priceNotIncluded, setPriceNotIncluded] = useState(true);
  const [uploadType, setUploadType] = useState(UploadType.file);

  const [removedImagesList, setRemovedImagesList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const { categoriesGetter } = useCategory();
  const { walletGetter } = useWallet();
  const navigate = useNavigate();

  const location = useLocation();
  const [resultPosts] = useQuery({
    query: getPostByPostId,
    variables: { id: postId },
  });

  const { data, postsDataFetching, postsDataError } = resultPosts;

  const item = data?.postingItem;

  const postURIRes = usePostURI(item?.uri);

  const toPreview = (values) => {
    if (!walletGetter.address) {
      message.warning({ content: "Wallet not connected" });
      return;
    }

    if (!values.title) {
      message.warning({ content: "Title is required" });
      return;
    }

    if (values?.category === -1) {
      message.warning({ content: "Category is required" });
      return;
    }

    if (values?.category !== "7" && values.variety === -1) {
      message.warning({
        content: "Sub-Category is required",
      });
      return;
    }

    if (!values.description || description === "<p><br></p>") {
      message.warning({ content: "Description is required" });
      return;
    }

    if (!priceNotIncluded) {
      if (!values.price) {
        message.warning({ content: "Price is required" });
        return;
      }
      try {
        if (
          isNaN(Number(values.price)) ||
          typeof Number(values.price) !== "number"
        ) {
          throw "";
        }
      } catch (error) {
        message.warning({ content: "Price must be number" });
        return;
      }
    }

    const res = images.find((fileObject) => !fileObject.uri);
    if (res) {
      message.warning({
        content: "Not all images uploaded successfully",
      });
      return;
    }

    let categoryName = "";
    let varietyName = "";
    categoriesGetter.map((category) => {
      if (category.id === values?.category) {
        categoryName = category.name;
        return category.varieties.map((variety) => {
          //   console.log(variety.id, variety.name);
          if (variety.id === values.variety) {
            varietyName = variety.name;
            return true;
          }
        });
      }
    });

    // setIsPreviewMode(true);
    // setPreviewMode(PreviewMode.preview);
    const item = {
      title: values.title,
      owner: walletGetter.address,
      updatedAt: `${Date.now()}`.slice(0, -3),
      category: {
        parent: { name: categoryName },
        name: varietyName,
      },
      categoryId: values?.category,
      varietyId: values.variety,
      postId,
      images,
      contacts: contactList,
      description: values.description,
      price: !priceNotIncluded ? values.price : "",
      priceNotIncluded,
    };
    setPreviewItem(item);

    navigate(`${location.pathname}/preview`, {
      state: {
        item,
        mode,
        // goBack: () => {
        //   setPreviewMode(PreviewMode.previewBack);
        //   navigate(-1);
        // },
      },
    });
    // setPreviewItem({
    //   title: values.name,
    //   owner: walletGetter.address,
    //   updatedAt: Date.now() / 1000,
    //   item: {
    //     category: {
    //       parent: { name: categoryName },
    //       name: varietyName,
    //     },
    //   },
    // });

    // if (
    //   mode === Mode.update &&
    //   walletGetter.address?.toLowerCase() !== item.owner
    // ) {
    //   message.warning({
    //     content:
    //       "Unable to update the post that do not belong to yourself",
    //   });
    //   return;
    // }
    // try {
    //   await submit(
    //     values.variety,
    //     values.name,
    //     values.contact,
    //     values.description,
    //     images,
    //     mode,
    //     postId
    //   );
    // } catch (err) {
    //   console.error(err);
    //   toast.error("Something went wrong!");
    //   setStatus({ success: false });
    //   setErrors({ submit: err });
    //   setSubmitting(false);
    // }
  };

  const pathname = location.pathname.split("/");
  const mode = pathname[3];

  useEffect(() => {
    if (
      (mode === Mode.update || mode === Mode.republish) &&
      postURIRes &&
      item &&
      !postURIIsLoaded
    ) {
      setTitle(item.title);
      setDescription(postURIRes.description);
      setContactList(postURIRes.contacts ?? []);
      setPrice(postURIRes.price);
      setPostURIIsLoaded(true);
    }
    //  else if (isPreviewMode && previewMode === PreviewMode.previewBack) {
    //   setTitle(previewItem.title);
    //   setCategoryId(previewItem.categoryId);
    //   // setVarietyId(previewItem.varietyId);
    //   setDescription(previewItem.description);
    //   setContactList(previewItem.contacts);
    //   setPriceNotIncluded(previewItem.priceNotIncluded);
    //   if (previewItem.priceNotIncluded) {
    //     setPrice("");
    //   } else {
    //     setPrice(previewItem.price);
    //   }
    //   // setIsPreviewMode(false);
    //   setPreviewMode();
    // }
  }, [mode, item, previewItem, postURIRes]);

  const handleSavedData = ({ selectValue, inputValue }) => {
    setContactList([...contactList, { key: selectValue, label: inputValue }]);
  };

  useEffect(() => {
    if (location.state?.new) {
      setPostId(0);
      setTitle();
      setDescription();
      setPrice();
      setCategoryId();
      setVarietyId();
      setIsDeletingPost();
      setImages([]);
      setChooseContactOpen(false);
      setPreviewItem();
      setPostURIIsLoaded(false);
      setPriceNotIncluded(true);
      setRemovedImagesList([]);
      setContactList([]);
    }
  }, [location]);

  useEffect(() => {
    setLocationChanged(true);

    // try {
    //   console.log(location);
    //   const item2 = location.state?.item;
    //   const mode = location?.state?.mode;
    //   console.log(item2, mode);
    //   if (item && mode) {
    //     item = item2;
    //     // setIsPreviewMode(PreviewMode.previewBack);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    if (mode === Mode.update || mode === Mode.republish) {
      setCategoryId(pathname[5]);
      setVarietyId(pathname[7]);
      setPostId(pathname[9]);
    }
    setTimeout(() => {
      setLocationChanged(false);
    }, 0);
  }, [location]);

  useEffect(() => {
    if (mode === Mode.new) {
      setImages([]);
    }
  }, [mode]);

  useEffect(() => {
    if (
      (mode === Mode.update || mode === Mode.republish) &&
      postURIRes?.images
    ) {
      setImages(
        postURIRes.images.map(({ uri, file }) => ({
          upload: `${process.env.REACT_APP_PinataGateWay}${uri}`,
          uri,
          id: nanoid(),
          file,
        }))
      );
    }
  }, [mode, postURIRes?.images]);

  // useEffect(() => {
  //   const pathname = location.pathname.split("/").slice(2).join("/");
  //   navigate(
  //     `/${web3.utils.toChecksumAddress(walletGetter.address)}/${pathname}`
  //   );
  // }, [walletGetter.address]);

  const imageHeight = "123px";

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        padding: "5vh 0",
      }}
      className={styles.container}
    >
      {!locationChanged && (
        <>
          {((mode === Mode.update || mode === Mode.republish) &&
            (!item || !postURIIsLoaded)) ||
          !categoriesGetter ? (
            <Loading />
          ) : (
            <Box className={styles.formContainer}>
              <Box className={styles.title}>
                {mode === Mode.republish
                  ? "Republish"
                  : mode === Mode.update
                  ? "Update"
                  : "Create a"}{" "}
                Listing
              </Box>
              <Formik
                initialValues={{
                  category: categoryId,
                  variety: varietyId,
                  description,
                  price,
                  // contact:
                  //   mode === Mode.update || mode === Mode.previewBack
                  //     ? contactList
                  //     : "",
                  title,
                  uri: item?.uri,
                }}
                // validationSchema={Yup.object().shape({
                //   // category: Yup.string()
                //   //   .max(255)
                //   //   .required("Category is required"),
                //   // variety: Yup.string().max(255).required("Variety is required"),
                //   // description: Yup.string()
                //   //   .max(5000)
                //   //   .required("Description is required"),
                //   // contact: Yup.string()
                //   //   .max(30)
                //   //   .required("Contact is required"),
                //   // images: Yup.array().required(),
                //   name: Yup.string().max(255).required("Name is required"),
                // })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => toPreview(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      className={styles.formContent}
                      container
                      columnSpacing={4}
                    >
                      <Grid className={styles.left} item md={6} xs={12} sm={12}>
                        <label className={styles.label}>Title</label>
                        <input
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Post Title"
                          name="title"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setTitle(e.currentTarget.value);
                            handleChange(e);
                          }}
                          value={values.title}
                          variant="standard"
                          // floatingLabelFocusStyle={{color: "black",}}
                          // inputProps={{
                          //   style: { fontFamily: "nunito", borderColor: "white" },
                          // }}
                        />
                        {(mode === Mode.new || mode === Mode.republish) && (
                          <>
                            <label className={styles.label}>
                              Sub-Category{" "}
                              {/* {categoryId === "7" && (
                                <Box
                                  className={styles.notRequired}
                                >{`( Not required )`}</Box>
                              )} */}
                            </label>
                            <select
                              fullWidth
                              label="Variety"
                              name="variety"
                              onChange={(e) => {
                                setVarietyId(
                                  e.target.options[e.target.selectedIndex].value
                                );
                                handleChange(e);
                              }}
                              select
                              value={values.variety}
                              variant="standard"
                            >
                              <option selected disabled value={-1} hidden>
                                {categoryId === "7"
                                  ? "Not required"
                                  : "Please choose"}
                              </option>
                              {categoriesGetter.length > 0 &&
                                categoriesGetter.map((category) => {
                                  // console.log(category.id, values.category);
                                  if (category.id === values?.category) {
                                    return category.varieties.map((variety) => {
                                      // console.log(
                                      //   variety.id,
                                      //   variety.name
                                      // );
                                      return (
                                        <option
                                          key={variety.id}
                                          value={variety.id}
                                        >
                                          {variety.name}
                                        </option>
                                      );
                                    });
                                  }
                                })}
                            </select>
                          </>
                        )}

                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ margin: "44px 0 12px" }}
                        >
                          <label style={{ margin: 0 }} className={styles.label}>
                            Price
                          </label>
                          <Grid style={{ margin: 0 }}>
                            <Checkbox
                              class={styles.checkbox}
                              label={"Not included"}
                              checked={priceNotIncluded}
                              onChange={(val) => {
                                setPriceNotIncluded(val);
                                if (val) {
                                  setPrice("");
                                  values.price = "";
                                }
                              }}
                              className={styles.checkbox}
                            />
                            <Box className={styles.notIncluded}></Box>
                          </Grid>
                        </Grid>
                        <input
                          error={Boolean(touched.price && errors.price)}
                          fullWidth
                          helperText={touched.price && errors.price}
                          name="price"
                          placeholder="Ex. $999"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setPrice(e.currentTarget.value);
                            setPriceNotIncluded(!e.currentTarget.value);
                            handleChange(e);
                          }}
                          value={values.price}
                          variant="standard"
                          // floatingLabelFocusStyle={{color: "black",}}
                          // inputProps={{
                          //   style: { fontFamily: "nunito", borderColor: "white" },
                          // }}
                        />

                        <Box className={styles.uploadTitle}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={UploadType.file}
                            onChange={(event) => {
                              setUploadType(event.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="file"
                              control={
                                <Radio
                                  sx={{
                                    marginRight: "3px !important",
                                  }}
                                />
                              }
                              label="Upload Image/File"
                              defaultChecked
                              def
                              sx={{
                                marginRight: "30px !important",
                              }}
                            />
                            <FormControlLabel
                              value="directory"
                              control={
                                <Radio
                                  sx={{
                                    marginRight: "3px !important",
                                  }}
                                />
                              }
                              label="Upload Directory"
                            />
                          </RadioGroup>
                          <label
                            style={{ justifyContent: "flex-end" }}
                            className={styles.label}
                          >
                            Add Images/ Files/ Directories {`(${images.length}/6)`}
                          </label>
                        </Box>
                        <Card
                          sx={{
                            position: "relative",
                            backgroundColor: "#F5F0FA",
                            boxShadow: "unset",
                            height: "295px",
                            // minHeight: "299px",
                            // maxHeight: mode === Mode.new ? "477px" : "377px",
                            overflow: "auto",
                            cursor: images.length === 0 && "pointer",
                          }}
                        >
                          {/* <CardHeader title="Upload Image" /> */}
                          {images.length > 0 ? (
                            <CardContent
                              sx={{
                                padding: "16px !important",
                              }}
                            >
                              <ImageUploader
                                imageHeight={imageHeight}
                                images={images}
                                setImages={setImages}
                                removedImagesList={removedImagesList}
                                uploadType={uploadType}
                              >
                                <UploadUI />
                              </ImageUploader>
                            </CardContent>
                          ) : (
                            <>
                              {uploadType === UploadType.directory ? (
                                <>
                                  <SelectOrDragUI>
                                    <UploadDirectoryUI
                                      images={images}
                                      setImages={setImages}
                                      removedImagesList={removedImagesList}
                                    />
                                  </SelectOrDragUI>
                                </>
                              ) : (
                                <ImageUploader
                                  imageHeight={imageHeight}
                                  images={images}
                                  setImages={setImages}
                                  removedImagesList={removedImagesList}
                                  uploadType={uploadType}
                                >
                                  <SelectOrDragUI />
                                </ImageUploader>
                              )}
                            </>
                          )}
                        </Card>
                      </Grid>

                      <Grid
                        className={styles.right}
                        item
                        md={6}
                        xs={12}
                        sm={12}
                      >
                        {(mode === Mode.new || mode === Mode.republish) && (
                          <>
                            <label className={styles.label}>Category</label>
                            <select
                              fullWidth
                              label="Category"
                              name="category"
                              onChange={(e) => {
                                setCategoryId(
                                  e.target.options[e.target.selectedIndex].value
                                );
                                setFieldValue("variety", -1);
                                handleChange(e);
                              }}
                              select
                              SelectProps={{ native: true }}
                              value={values?.category}
                              variant="standard"
                              sx={{
                                borderColor: "#999999",
                                ".MuiInputLabel-root": {
                                  fontFamily: "Satoshi-Regular",
                                  color: "#999",
                                },
                              }}
                            >
                              {" "}
                              <option selected disabled value={-1} hidden>
                                Please choose
                              </option>
                              {categoriesGetter.length > 0 &&
                                categoriesGetter.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        )}

                        <label className={styles.label}>Contact</label>
                        <ChipsArray
                          chipData={contactList}
                          setChipData={setContactList}
                          onClickAdd={() => setChooseContactOpen(true)}
                        />
                        <label className={styles.label}>Description</label>
                        <QuillEditor
                          label="description"
                          name="description"
                          onChange={(value) => {
                            setDescription(value);
                            setFieldValue("description", value);
                            handleChange(value);
                          }}
                          placeholder="Write your description here."
                          sx={{
                            height: 418,
                            mb: 2,
                            mt: 3,
                            borderColor: "#bbb",
                            fontSize: "12px",
                            ".ql-snow.ql-container .ql-editor": {
                              overflow: "auto",
                              height: "calc(100% - 41px)",
                              fontFamily: "Satoshi-Medium",
                              fontSize: "13px",
                              color: "#333",
                            },
                            ".ql-snow.ql-container .ql-editor.ql-blank::before":
                              {
                                fontFamily: "Satoshi-Medium",
                                fontSize: "13px",
                                color: "#666666",
                              },
                          }}
                          className={styles.description}
                          value={values.description}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        p: "40px 0 20px !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 3,
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "179px",
                            height: "44px",
                            borderRadius: "4px",
                            fontFamily: "Satoshi-Bold",
                            fontSize: "16px",
                            color: "#3D266F",
                            border: "1px solid #3D266F",
                            mr: "16px",
                            cursor: "pointer",
                          }}
                          className="cancelButton"
                          onClick={() =>
                            navigate(
                              `${
                                mode === Mode.republish
                                  ? "/my-history"
                                  : "/my-posts"
                              }`
                            )
                          }
                        >
                          Cancel
                        </Box>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            width: "179px",
                            height: "44px",
                            borderRadius: "4px",
                            fontFamily: "Satoshi-Bold",
                            fontSize: "16px",
                            cursor: "pointer",
                            border: "unset",
                          }}
                          className="confirmButton"
                        >
                          {isSubmitting ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "black" }}
                            />
                          ) : (
                            <>
                              {/* {mode === Mode.new
                                ? "Create Post"
                                : "Update Post"} */}
                              Continue
                            </>
                          )}
                        </button>
                      </Box>
                    </Grid>
                  </form>
                )}
              </Formik>
              <ChooseContact
                isOpen={chooseContactOpen}
                setOpen={setChooseContactOpen}
                savedData={contactList}
                handleSavedData={handleSavedData}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const UploadDirectoryUI = ({ images, setImages, removedImagesList }) => {
  return (
    <Box
      component="input"
      type="file"
      webkitdirectory=""
      multiple="multiple"
      accept="*/*"
      className={styles.uploadDirectory}
      onChange={(e) =>
        onDirectoryUpload({ e, images, setImages, removedImagesList })
      }
    />
  );
};

const SelectOrDragUI = ({ children }) => {
  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
      className={styles.uploadBox}
    >
      <Box component="img" src={uploadImgIcon}></Box>
      <Box className={styles.uploadTip1}>Select from Computer</Box>
      <Box className={styles.uploadTip2}>or drag and drop</Box>
      {children}
    </Grid>
  );
};

const UploadUI = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // width: "109px",
        // height: imageHeight,
        position: "relative",
        cursor: "pointer",
        background: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          paddingBottom: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box component={"img"} src={uploadSvg} />
          <Box
            sx={{
              fontFamily: "Satoshi-Regular",
              fontSize: "14px",
              mt: 2,
              color: "#999",
            }}
          >
            Upload
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default ProductCreateForm;
