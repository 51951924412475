import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux/modules/wallet";
import { getWallet } from "../../redux/modules/entities/wallet";
import { getWalletStatus } from "../../redux/modules/wallet";
import { useEffect } from "react";

const useWallet = () => {
  const dispatch = useDispatch();

  const walletDispatcher = bindActionCreators(actions, dispatch);

  const setChain = async (chainId) => await walletDispatcher.setChain(chainId);
  const setAddress = async (address) =>
    await walletDispatcher.setAddress(address);

  useEffect(() => {
    window.ethereum?.on("chainChanged", (chainId) => {
      setChain(chainId);
    });
    window.ethereum?.on("accountsChanged", (address) => {
      setAddress(address[0]);
    });
  }, []);

  return {
    setChain,
    setAddress,
    walletConfigurator: async () => await walletDispatcher.walletConfigurator(),
    switchChain: async (chainId) => await walletDispatcher.switchChain(chainId),
    walletSetter: async (wallet) => await walletDispatcher.setWallet(wallet),
    walletDisconnetor: async () => await walletDispatcher.disconnectWallet(),
    allowanceChecker: async () => await walletDispatcher.checkAllowance(),
    walletProver: async () => await walletDispatcher.makeApprove(),
    walletGetter: useSelector((state) => getWallet(state)),
    walletStatusGetter: useSelector((state) => getWalletStatus(state)),
  };
};

export default useWallet;
