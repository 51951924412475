import { Box, Grid, ImageList, ImageListItem, useMediaQuery } from "@mui/material";
import styles from "./index.module.css";
import { addressAbbreviation } from "@/utils/format";
import classificationIcon from "@/assets/icon/classification.svg";
import clockIcon from "@/assets/icon/clock.svg";
import dcIcon from "@/assets/icon/dc_black.svg";
import twitterIcon from "@/assets/icon/twitter_black.svg";
import githubIcon from "@/assets/icon/github_black.svg";
import shareIcon from "@/assets/icon/share.svg";
import ethIcon from "@/assets/icon/eth.svg";
import usePostURI from "@/hooks/aboutPost/usePostURI";
import { useEffect, useState } from "react";
import message from "@/components/Message";
import Swiper from "../Swiper";
import DefaultImageBox from "../DefaultImageBox";
import emailIcon from "@/assets/icon/email_black.svg";
import { NavLink as RouterLink } from "react-router-dom";
import CreatePostButton from "../CreatePostButton";

export default function PostDetailCard({
  item,
  isPublished,
  titleClass,
  extraInfoClass,
  postCardClass,
}) {
  const [metadata, setMetadata] = useState();
  const postURIMetadata = usePostURI(item?.uri);
  const matches = useMediaQuery("(max-width:600px)");

  const copyLink = () => {
    let input = document.querySelector("#copyLink");
    if (!input) {
      input = document.createElement("input");
      input.setAttribute("id", "copyLink");
      input.style.opacity = "0";
      input.style.position = "absolute";
      input.style.left = "-999px";
      document.body.append(input);
    }

    input.value = location.href;
    input.select();
    document.execCommand("copy");
    message.success({
      content: "Copy Link successfully!",
    });
  };

  useEffect(() => {
    let contacts = (
      postURIMetadata?.contacts?.length > 0
        ? postURIMetadata.contacts
        : item.contacts
    )?.map((item) => {
      if (item.key === "Email") {
        item.icon = emailIcon;
      } else if (item.key === "Twitter") {
        item.icon = twitterIcon;
      } else if (item.key === "Discord") {
        item.icon = dcIcon;
      } else if (item.key === "Github") {
        item.icon = githubIcon;
      }
      return item;
    });

    if (!item?.uri) {
      setMetadata({
        contacts,
        images: item.images,
        description: item.description,
        price: item.price,
      });
    } else {
      setMetadata({
        ...postURIMetadata,
        contacts,
        images: postURIMetadata?.images?.map(({ uri, file }) => ({
          upload: `${process.env.REACT_APP_PinataGateWay}${uri}`,
          uri,
          file,
        })),
      });
    }
  }, [item?.uri, JSON.stringify(postURIMetadata)]);

  return (
    <Box className={styles.container}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Box className={`${styles.title} ${titleClass}`}>{item?.title}</Box>
        {isPublished && (
          <Grid
            item
            container
            width={"auto"}
            alignItems="center"
            className={styles.toolbar}
          >
            <Box className={`${styles.share}`} onClick={copyLink}></Box>
            {!matches && <CreatePostButton />}
          </Grid>
        )}
      </Grid>

      <Grid className={`${styles.extraInfo} ${extraInfoClass}`}>
        <Box className={styles.item}>{addressAbbreviation(item.owner)}</Box>
        <Box className={styles.item}>
          <Box component="img" src={clockIcon}></Box>
          {new Date(Number(`${item.updatedAt}000`))
            .toDateString()
            .split(" ")
            .slice(1, -1)
            .join(" ") +
            ", " +
            new Date(Number(`${item.updatedAt}000`)).getUTCFullYear()}
        </Box>
        <Box className={styles.item}>
          <Box component="img" src={classificationIcon}></Box>
          <Box
            component={RouterLink}
            to={`/category/${item?.category?.parent?.id}`}
            sx={{ color: "#1a202c" }}
          >
            {item?.category?.parent?.name}
          </Box>
          &nbsp;
          {item?.category?.name && `/`}
          &nbsp;
          {item?.category?.name && (
            <Box
              component={RouterLink}
              to={`/category/${item?.category?.parent.id}/variety/${item?.category?.id}`}
              sx={{ color: "#1a202c" }}
            >
              {item?.category?.name}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid className={`${styles.postCard} ${postCardClass}`}>
        <Grid
          sx={{
            // height: "400px",
            backgroundColor: metadata?.images?.length === 0 && "#eff5f9",
            flex: 2,
            position: "relative",
            "@media (min-width: 600px)": {
              maxWidth: "60%",
            },
            "@media (max-width: 600px)": {
              width: "100%",
            },
            // height: "100%",
          }}
        >
          {/* <ImageList
            sx={{ height: "100%", overflow: "auto" }}
            cols={1}
            className="hideScrollbar"
          > */}
          {metadata?.images?.length > 0 ? (
            <Swiper images={metadata?.images} autoPlay showEthIcon />
          ) : (
            <>
              <DefaultImageBox />
              {/* <Box component="img" className={styles.eth} src={ethIcon}></Box> */}
            </>
          )}
          {/* {metadata?.images?.map((uri) => (
              <>
                {typeof uri === "string" ? (
                  <ImageListItem key={uri}>
                    <img
                      src={`${process.env.REACT_APP_PinataGateWay}${uri}`}
                      srcSet={`${process.env.REACT_APP_PinataGateWay}${uri}`}
                      loading="lazy"
                    />
                    <Box
                      component="img"
                      className={styles.eth}
                      src={ethIcon}
                    ></Box>
                  </ImageListItem>
                ) : (
                  <ImageListItem key={uri.id}>
                    <img
                      src={`${uri.upload}`}
                      srcSet={`${uri.upload}`}
                      loading="lazy"
                    />
                    <Box
                      component="img"
                      className={styles.eth}
                      src={ethIcon}
                    ></Box>
                  </ImageListItem>
                )}
              </>
            ))} */}
          {/* </ImageList> */}
        </Grid>
        <Grid
          sx={{
            padding: `20px 32px ${
              metadata?.contacts?.length === 0 ? "20px" : "0"
            } 48px`,
            flex: 1,
            // overflow: "auto",
            "@media (max-width: 600px)": {
              padding: "10px",
              width: "100%",
            },
          }}
        >
          <Grid
            sx={{
              // minHeight: "600px",
              maxHeight: "100%",
              overflow: "auto",
            }}
            className="hideScrollbar"
          >
            <Box
              className={`${styles.priceDescriptionBox} hideScrollbar`}
              sx={{
                height: `calc(30vw - ${
                  metadata?.contacts?.length === 0 ? "76px" : "52px"
                } - ${
                  metadata?.contacts?.length === 0
                    ? 0
                    : metadata?.contacts?.length * 36 + 62
                }px)`,
              }}
            >
              {metadata?.price && (
                <Box className={`${styles.price}`}>${metadata?.price}</Box>
              )}
              <Box className={styles.title}>Description</Box>
              <Box
                dangerouslySetInnerHTML={{ __html: metadata?.description }}
                className={styles.description}
              ></Box>
            </Box>
            {metadata?.contacts?.length > 0 && (
              <>
                <Box sx={{ padding: "20px 0 10px" }}>Contact</Box>
                <Box>
                  {metadata?.contacts?.map((item) => (
                    <Box className={styles.contactBox}>
                      <Box
                        component="img"
                        src={item.icon}
                        sx={{
                          transform: item.key === "Email" && "translateY(-3px)",
                        }}
                      ></Box>
                      <Box className={styles.contacts}>{item.label}</Box>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
