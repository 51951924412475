import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import styles from "./index.module.css";
import message from "../Message";
import bellIcon from "@/assets/icon/bell.svg";
import notificationIcon from "@/assets/icon/notification.svg";
import notificationDotIcon from "@/assets/icon/notificationDot.svg";
import cancelIcon from "@/assets/icon/cancel.svg";
import useEvent from "@/hooks/aboutEvent/useEvent";
import useWallet from "@/hooks/aboutWallet/useWallet";

export default function Notification() {
  const [open, setOpen] = useState(false);
  const { walletGetter } = useWallet();
  const [showDot, setShowDot] = useState(
    localStorage.getItem(`${walletGetter.address}:showDot`) === "true"
  );
  const { eventDispatcher, eventGetter } = useEvent();
  const [lock, setLock] = useState(true);

  const openNotification = (e) => {
    e.stopPropagation();
    setOpen(true);
    setShowDot(false);
    walletGetter.address &&
      localStorage.setItem(`${walletGetter.address}:showDot`, false);
  };

  const closeNotification = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (walletGetter.address) {
      setShowDot(
        localStorage.getItem(`${walletGetter.address}:showDot`) === "true"
      );
    }
  }, [walletGetter.address]);

  useEffect(() => {
    setTimeout(() => {
      setLock(false);
    }, 1000);
    if (lock) {
      return;
    }
    setShowDot(true);
    walletGetter.address &&
      localStorage.setItem(`${walletGetter.address}:showDot`, true);
  }, [eventGetter]);

  useEffect(() => {
    window.addEventListener("click", closeNotification);
    return () => {
      window.removeEventListener("click", closeNotification);
      // window.removeEventListener("storage", updateEventList);
    };
  }, []);

  return (
    <Box className={styles.container}>
      <Box
        // src={showDot ? notificationDotIcon : notificationIcon}
        // component="img"
        className={
          showDot ? styles.notificationDotIcon : styles.notificationIcon
        }
        onClick={openNotification}
      ></Box>
      {open && (
        <Box
          className={`${styles.modalContainer} hideScrollbar`}
          onClick={(e) => e.stopPropagation()}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.titleBox}
          >
            <Box className={styles.title}>Notification</Box>
            <Box
              src={cancelIcon}
              component="img"
              className={styles.icon}
              onClick={closeNotification}
            ></Box>
          </Grid>
          <Box>
            {eventGetter?.map((item) => (
              <Grid container className={styles.item}>
                <Grid flex={1}>
                  <Box
                    item
                    src={bellIcon}
                    component="img"
                    className={`${styles.bell}`}
                  ></Box>
                </Grid>
                <Box item flex={20}>
                  <Box className={styles.createdAt}>{item.createdAt}</Box>
                  <Box className={styles.textBox}>
                    <Box className={styles.type} component="span">
                      {item.type}{" "}
                    </Box>
                    <Box className={styles.title} component="span">
                      {item.title}{" "}
                    </Box>
                    <Box className={styles.status} component="span">
                      {item.status}
                      {"."}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
