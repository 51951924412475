import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { AliveScope } from "react-activation";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider as UrqlProvider } from "urql";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import useWallet from "./hooks/aboutWallet/useWallet";
import { chainMap, defaultChainId } from "./config";
import { createURQLClient } from "./graphQL";
import routes from "./routes";
import { getChain } from "./redux/modules/wallet";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppContainer = () => {
  const { walletGetter } = useWallet();

  const chainConfig = chainMap[getChain()];

  let [graphqlClient, setGraphqlClient] = useState(
    createURQLClient(
      chainConfig?.inuse
        ? chainConfig.graphURL
        : chainMap[defaultChainId].graphURL
    )
  );

  useEffect(() => {
    if (walletGetter.chainId) {
      setGraphqlClient(
        createURQLClient(
          chainConfig?.inuse
            ? chainConfig.graphURL
            : chainMap[defaultChainId].graphURL
        )
      );
    }
  }, [walletGetter.chainId]);

  return (
    <UrqlProvider value={graphqlClient}>
      <AliveScope>
        <RouterProvider router={routes}>{/* <App /> */}</RouterProvider>
      </AliveScope>
    </UrqlProvider>
  );
};

root.render(
  <ReduxProvider store={store.store}>
    <PersistGate persistor={store.persistor}>
      <AppContainer />
    </PersistGate>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
