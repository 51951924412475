import { Box, Button, Grid, Link } from "@mui/material";
import useWallet from "../../hooks/aboutWallet/useWallet";
import PostsList from "../../components/PostsList";
import { useEffect, useState } from "react";
import useWalletModal from "../../hooks/aboutWallet/useWalletModal";
import Sidebar from "@/components/Sidebar";
import styles from "./index.module.css";
import { useNavigate, NavLink } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import useEvent from "@/hooks/aboutEvent/useEvent";
import KeepAlive, {
  withAliveScope,
  useAliveController,
} from "react-activation";

const MyPosts = () => {
  const { walletGetter } = useWallet();
  const { isOpen, setOpen } = useWalletModal();
  const [quantity, setQuantity] = useState(0);
  const nav = useNavigate();
  const { drop, dropScope, clear, getCachingNodes } = useAliveController();

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!walletGetter.address) {
      handleClickOpen();
    } else {
      setOpen(false);
    }
  }, [walletGetter.address]);
  // const { eventDispatcher } = useEvent(false);

  return (
    <>
      <Sidebar></Sidebar>
      <Box className={styles.rightContainer}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignContent="center"
        >
          <Box item className={styles.title}>
            My Listings
          </Box>
          <Box
            item
            className={styles.create}
            // component={NavLink}
            // to={`/${walletGetter.address}/post/new`}
            // state={{ new: true, timestamp: Date.now() }}
            onClick={async () => {
              await drop("post");
              nav(`/${walletGetter.address}/post/new`, {
                state: { new: true },
                // replace: true,
              });
            }}
            // onClick={() => {
            //   eventDispatcher({
            //     type: "Your listing",
            //     title:"asdad",
            //     status: "has been published",
            //   });
            // }}
          >
            <AddCircleOutlineIcon
              sx={{ transform: "translateX(5px)" }}
            ></AddCircleOutlineIcon>
            <Box className={styles.text}>
              Create Listing
              <Box className={styles.decoration}></Box>
            </Box>
          </Box>
        </Grid>
        <Box className={styles.active}>Active Listings</Box>
        <Box className={styles.quantity}>{quantity} listings in total</Box>
        {walletGetter.address && (
          <PostsList
            owner={walletGetter.address}
            padding={0}
            setQuantity={setQuantity}
          />
        )}
        {/* <WalletConnectModal isOpen={isOpen} setOpen={setOpen} /> */}
      </Box>
    </>
  );
};

export default MyPosts;
