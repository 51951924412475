import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OfficialCheckbox from "@material-ui/core/Checkbox";

const CustomCheckbox = withStyles({
  root: {
    // width: "16px",
    // height: "16px",
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #bbbbbb",
        borderRadius: 3,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 13,
        width: 13,
        position: "absolute",
        backgroundColor: "#000",
        zIndex: -1,
        borderColor: "transparent",
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "1px solid #bbbbbb",
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 13,
      width: 13,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  checked: {},
})(OfficialCheckbox);

export default function Checkbox({
  label,
  onChange,
  style,
  className,
  checked,
}) {
  const [state, setState] = React.useState(false);

  const handleChange = (event) => {
    setState(event?.target?.checked);
    onChange?.(event?.target?.checked);
  };

  useEffect(() => {
    setState(checked);
  }, [checked]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={state}
            onChange={handleChange}
            size="small"
          />
        }
        label={label}
        style={style}
        className={className}
      />
    </FormGroup>
  );
}
