import { Box } from "@mui/material";
import logo from "@/assets/logo/logo_with_text.png";
import styles from "./index.module.css";
import useWallet from "@/hooks/aboutWallet/useWallet";
import { useConnect } from "wagmi";
import { getChain } from "@/redux/modules/wallet";
import { useNavigate } from "react-router-dom";
import { useAliveController } from "react-activation";
import message from "../Message";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function CreatePostButton({ classname }) {
  const { walletGetter, walletSetter } = useWallet();
  const { connectAsync, connectors } = useConnect();
  const { drop, dropScope, clear, getCachingNodes } = useAliveController();
  const nav = useNavigate();

  const createPost = async () => {
    if (!walletGetter.address) {
      if (typeof window.ethereum === "undefined") {
        message.warning({ content: "Please install MetaMask" });
        return;
      }
      const { account } = await connectAsync({
        connector: connectors[0],
      });
      const chain = getChain();
      console.log(`[WalletConnect]: account ${account}, chain: ${chain}`);
      walletSetter({
        address: account?.toLowerCase(),
        chain: getChain(),
      });
      localStorage.setItem("DisconnectWallet", "false");

      walletGetter.address = account?.toLowerCase();
    }
    await drop("post");
    nav(`/${walletGetter.address}/post/new`, {
      state: { new: true },
      // replace: true,
    });
  };
  return (
    <Box className={`${styles.create} ${classname}`} onClick={createPost}>
      <AddCircleOutlineIcon
        sx={{ transform: "translateX(5px)" }}
      ></AddCircleOutlineIcon>
      <Box className={styles.text}>
        Create Listing
        <Box className={styles.decoration}></Box>
      </Box>
    </Box>
  );
}
