import { useEffect, useState } from "react";
import axios from "axios";

const usePostURI = (uri) => {
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState("");
  const [contacts, setContacts] = useState([]);
  const [price, setPrice] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);

  const getIPFSData = ({
    url,
    url2,
    method = "get",
    callback,
    setHasLoadedFlag = true,
  }) => {
    axios[method](url)
      .then((res) => {
        callback(res);
      })
      .catch(() => {
        axios[method](url2)
          .then((res) => {
            callback(res);
          })
          .catch(() => {
            callback();
          })
          .finally(() => {
            setHasLoadedFlag && setHasLoaded(true);
          });
      })
      .finally(() => {
        setHasLoadedFlag && setHasLoaded(true);
      });
  };

  const getFileType = (uri) => {
    return new Promise((resolve) => {
      getIPFSData({
        url: `${process.env.REACT_APP_PinataGateWay}${uri}`,
        url2: `${process.env.REACT_APP_PinataGateWay2}${uri}`,
        method: "head",
        callback: (res) => {
          resolve(res.headers["content-type"] ?? "image/png");
        },
        setHasLoadedFlag: false,
      });
    });
  };

  useEffect(() => {
    if (uri) {
      getIPFSData({
        url: `${process.env.REACT_APP_PinataGateWay}${uri.replace(
          "ipfs://",
          ""
        )}`,
        url2: `${process.env.REACT_APP_PinataGateWay2}${uri.replace(
          "ipfs://",
          ""
        )}`,
        callback: async (res) => {
          if (res) {
            const { description, contacts, images, price } = res.data;
            setDescription(description);
            setContacts(contacts);
            setPrice(price);
            const newImages = await Promise.all(
              images.map(async (image) => {
                let uri;
                let type;
                let name;
                if (typeof image === "object") {
                  uri = image.cid.replace("ipfs://", "");
                  type = image.type;
                  name = image.name;
                  if (!type) {
                    type = await getFileType(uri);
                  }
                } else {
                  uri = image.replace("ipfs://", "");
                  type = await getFileType(uri);
                }
                return { file: { type, name }, uri };
              })
            );
            setImages(newImages);
          }
        },
      });
    }
  }, [uri]);

  if (hasLoaded) {
    return {
      description,
      contacts,
      images,
      price,
    };
  }

  return;
};

export default usePostURI;
