import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { listenForTransactionMine } from "@/utils/contract";
import { ethers } from "ethers";
import { Box, Grid } from "@mui/material";
import message from "@/components/Message";
import { switchChain, getChain } from "@/redux/modules/wallet";
import { chainMap, defaultChainId } from "@/config";
import styles from "./index.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import cancelIcon from "@/assets/icon/cancel.svg";
import abi from "@/abi/postAbi.json";
import useEvent from "@/hooks/aboutEvent/useEvent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Delete({
  each,
  isOpen,
  setOpen,
  reexecuteQuery,
}) {
  const [loading, setLoading] = useState(false);
  const { eventDispatcher } = useEvent();

  const handleDelete = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      let chainId = getChain();
      if (!chainMap[chainId]?.inuse) {
        await switchChain(defaultChainId);
        chainId = defaultChainId;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        chainMap[chainId].postingContract,
        abi,
        signer
      );

      const transactionResponse = await contract.remove(each.id);
      console.log(transactionResponse);
      await listenForTransactionMine(transactionResponse, provider);
      console.log(`Done Deleting`);
      message.success({
        content: "Delete successfully!",
      });

      eventDispatcher({
        type: "Your listing",
        title: each.title,
        status: "has been deleted",
      });

      reexecuteQuery();
    } catch (error) {
      const err = error.data?.message ?? error.message;
      message.error({ content: err });
    }
    setLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(-1);
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className={styles.dialog}
      onClick={(e) => e.stopPropagation()}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "unset",
        },
      }}
    >
      <Box className={styles.dialogContainer}>
        <DialogContent className={styles.dialogContent}>
          <Grid container justifyContent="space-between">
            Delete this Listing
            <Box
              component="img"
              src={cancelIcon}
              sx={{ cursor: "pointer" }}
              onClick={handleClose}
            ></Box>
          </Grid>
          <Box className={styles.tip}>
            After deletion, your staking will be returned, with a certain amount
            of gas fee paid.
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Box onClick={handleClose} className="cancelButton">
            Cancel
          </Box>
          <Box onClick={handleDelete} className="deleteButton">
            {loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              "Delete"
            )}
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
