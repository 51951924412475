import { Box } from "@mui/material";
import logo from "@/assets/logo/logo_with_text.png";
import styles from "./index.module.css";

export default function DefaultImageBox() {
  return (
    <Box className={styles.container}>
      <Box component="img" src={logo} className={styles.logo}></Box>
    </Box>
  );
}
