/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/

import {types} from "../categories";

const initialState = []


export const schema = {
    name: "categories",
    id: "id",
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CATEGORY_FETCHER.success():
            return action.payload
        default:
            return state;
    }
}


export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getCategories = (state) => {
    return state.entities.categories
}