import { React, useEffect, useState } from "react";
import { experimentalStyled } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import WagmiProvider from "../contexts/wagmiContext";
import App from "@/App";
import NavBarPreview from "@/components/NavBarPreview";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  background: "#FEFDFF",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  // display: "flex",
  // flex: "1 1 auto",
  width: "100%",
  // overflow: "auto",
  paddingTop: "64px",
  // [theme.breakpoints.up("lg")]: {
  //   // paddingLeft: "280px",
  // },
  // backgroundColor: "#eff5f9",
  // height: "calc(100vh - 64px)",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  // display: "flex",
  flex: "1 1 auto",
  // overflow: "auto",
  backgroundColor: "#eff5f9",
});

const DashboardLayoutContent = experimentalStyled("div")({
  // flex: "1 1 auto",
  // height: "fit-content",
  // overflow: "auto",
  position: "relative",
  // margin: "2rem",
  backgroundColor: "white",
  WebkitOverflowScrolling: "touch",
  height: "100%",
  // borderRadius: "2%",
});

const DashboardLayout = () => {
  const location = useLocation();

  return (
    <DashboardLayoutRoot>
      <App />
      <WagmiProvider>
        {location.pathname === "/preview-dashboard" ? (
          <NavBarPreview />
        ) : (
          <NavBar />
        )}
        {/* <DashboardSidebar />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
        <Outlet />
        </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper> */}
        <DashboardLayoutWrapper
          sx={{
            "@media (max-width: 600px)": {
              paddingTop:
                location.pathname === "/preview-dashboard" ? "10px" : "64px",
            },
          }}
        >
          <Outlet />
        </DashboardLayoutWrapper>
      </WagmiProvider>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
