import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as searchActions } from "../../redux/modules/entities/searchParams";
import { getSearchParams } from "../../redux/modules/entities/searchParams";

const useSearch = () => {
  const dispatch = useDispatch();
  const searchDispatcher = bindActionCreators(searchActions, dispatch);

  return {
    searchDispatcher: (title, description) =>
      searchDispatcher.search(title, description),
    searchParamsGetter: useSelector((state) => getSearchParams(state)),
  };
};

export default useSearch;
