import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme, TextField, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavLink as RouterLink } from "react-router-dom";
import logo from "@/assets/logo/logo_with_text.png";
import WalletConnect from "../WalletConnect";
import emitter from "@/utils/eventEmitter";
import styles from "./index.module.css";
import Notification from "@/components/Notification";
import useWallet from "@/hooks/aboutWallet/useWallet";
import menuIcon from "@/assets/icon/menu.svg";

export default function NavBar(props) {
  const { window } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { walletGetter } = useWallet();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", pl: 0 }}>
      <AppBar
        component="nav"
        style={{ backgroundColor: "white", pl: 0 }}
        elevation={0}
      >
        <Toolbar className={styles.container}>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box
            component={RouterLink}
            to={"/"}
            onClick={() => {
              // navigate('/')
              emitter.emit("resetDashboard");
            }}
            className={styles.logoBox}
          >
            {/* <Typography
              color={theme.palette.primary.main}
              variant="h2"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex" },
                flexDirection: "colomn",
                justifyContent: "center",
              }}
            > */}
            <Box
              component="img"
              src={logo}
              sx={{ width: "100px" }}
              className={styles.logo}
            ></Box>
            {/* </Typography> */}
          </Box>
          {/* <Box
            sx={{
              width: "1px",
              height: "5vh",
              background: "#ccc",
              margin: "0 70px",
            }}
          ></Box> */}

          <Box
            // sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            className={styles.right}
          >
            <Box className={styles.navBox}>
              <Link
                className={styles.nav}
                href={process.env.REACT_APP_ABOUT_ELS}
                target="_blank"
              >
                About ELS
                <Box className={styles.decoration}></Box>
              </Link>
              <Box className={styles.nav} component={RouterLink} to={"/dao"}>
                DAO Governance
                <Box className={styles.decoration}></Box>
              </Box>
              {walletGetter.address && <Notification />}
            </Box>
            <WalletConnect></WalletConnect>
          </Box>
          <Box component="img" className={styles.menu} src={menuIcon}></Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
