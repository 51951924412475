import { Grid, Box, Button, ImageList, ImageListItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "urql";
import { getPostByPostId } from "../../graphQL";
import useWallet from "../../hooks/aboutWallet/useWallet";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import axios from "axios";
import usePostURI from "../../hooks/aboutPost/usePostURI";
import styles from "./index.module.css";
import { addressAbbreviation } from "@/utils/format";
import classificationIcon from "@/assets/icon/classification.svg";
import clockIcon from "@/assets/icon/clock.svg";
import dcIcon from "@/assets/icon/dc_black.svg";
import twitterIcon from "@/assets/icon/twitter_black.svg";
import githubIcon from "@/assets/icon/github_black.svg";
import Footer from "@/components/Footer";
import PostDetailCard from "@/components/PostDetailCard";
import CreatePostButton from "@/components/CreatePostButton";

const PostDetail = () => {
  const location = useLocation();
  const [postId, setPostId] = useState(0);
  const navigate = useNavigate();
  const { walletGetter } = useWallet();
  const [resultPosts] = useQuery({
    query: getPostByPostId,
    variables: { id: postId },
  });
  const { data, postsDataFetching, postsDataError } = resultPosts;

  const item = data?.postingItem;

  // const { description, contacts, images } = usePostURI(item?.uri);

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const postId = pathname[6];
    setPostId(postId);
  }, [location]);

  return (
    <Box className={styles.container}>
      {!item ? (
        <Loading />
      ) : (
        <>
          <PostDetailCard
            item={item}
            isPublished
            // titleClass={styles.postDetailCardBoxTitle}
            // extraInfoClass={styles.postDetailCardBoxExtraInfo}
            // postCardClass={styles.postCardClass}
          />
          <Footer className={styles.footer} />
        </>
      )}
    </Box>
  );
};
export default PostDetail;
