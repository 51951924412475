import Scrollbar from "../Scrollbar";
import NavSection from "../NavSection";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import useWallet from "../../hooks/aboutWallet/useWallet";
import personIcon from "@/assets/icon/person.svg";
import marketIcon from "@/assets/icon/market.svg";
import historyIcon from "@/assets/icon/history.svg";
import logo from "@/assets/logo/logo_with_text.png";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const Sidebar = () => {
  const { walletGetter } = useWallet();
  const nav = useNavigate();
  const sections = [
    {
      title: "",
      items: [
        {
          title: "Create Listing",
          path: "/create-posts",
          icon: <AddCircleOutlineIcon sx={{ color: "#302656" }} />,
        },
        {
          title: "My Listing",
          path: "/my-posts",
          icon: <Box component="img" src={personIcon} />,
        },
        {
          title: "My History",
          path: "/my-history",
          icon: <Box component="img" src={historyIcon} />,
        },
        {
          title: "Marketplace",
          path: `/`,
          icon: <Box component="img" src={marketIcon} />,
        },
        // {
        //     title: "Favorites",
        //     path: "/",
        //     icon: <FavoriteIcon fontSize="small" />,
        // },
        // {
        //   title: "DAO",
        //   path: "/dao",
        //   icon: <DaoSvg />,
        // },
      ],
    },
  ];

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {/*connect wallet*/}
        {/* <WalletConnect /> */}

        <Box sx={{ p: 2, mt: "10px" }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          top: 0,
          width: 216,
          borderRight: "1px solid #F2F4F6",
          background: "#FEFDFF",
        },
      }}
      variant="permanent"
    >
      <Box
        component="img"
        src={logo}
        sx={{ margin: "30px auto 0", width: "65%", cursor: "pointer" }}
        onClick={() => {
          nav("/");
        }}
      ></Box>
      {content}
    </Drawer>
  );
};

export default Sidebar;
